<template>
  <span>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-app id="inspire" class="mt-5">
      <v-main>
        <v-alert v-if="error" :value="true" color="error">
          {{ error }}
        </v-alert>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm6 md7>
              <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{
                                      $t("users.registration")
                                      }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-autocomplete v-model="locale" item-text="text" item-value="value" :items="locales"
                    :label="$t('companies.locale')" :placeholder="$t('companies.locale_plc')"></v-autocomplete>
                  <v-text-field v-model.trim="user_name" :label="$t('users.your_name')"
                    :placeholder="$t('users.your_name_placeholder')"></v-text-field>
                  <v-text-field v-model.trim="company_name" :label="$t('users.company_name')"
                    :placeholder="$t('users.company_name_placeholder')"></v-text-field>
                  <v-autocomplete :label="$t('users.country')" v-model="country" :items="countries" @change="phoneCode()"
                    item-text="both" item-value="name" auto-select-first return-object>
                  </v-autocomplete>
                  <v-text-field v-model.trim="city" :label="$t('users.city')"
                    :placeholder="$t('users.city_placeholder')"></v-text-field>
                  <v-autocomplete v-model="time_zone" item-text="text" item-value="value" :items="time_zones"
                    :label="$t('users.time_zone')" :placeholder="$t('users.time_zone_placeholder')"></v-autocomplete>
                  <v-text-field v-model.trim="phone" v-mask="'+############'" :label="$t('users.phone')"
                    :placeholder="$t('users.phone_placeholder')" :error="phone_error" :error-messages="phone_error_text"
                    @input="phone_error = false, phone_error_text = ''"></v-text-field>
                  <v-text-field v-model.trim="email" label="Email*" autocomplete="email"></v-text-field>
                  <v-text-field v-model.trim="password" :append-icon="show1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                                      " :type="show1 ? 'text' : 'password'" name="input-10-1" :hint="$t('users.password_length')" counter
                    @click:append="show1 = !show1" :label="$t('users.password_most')" required
                    autocomplete="current-password"></v-text-field>
                  <v-text-field v-model.trim="password_confirmation" :append-icon="show1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                                      " :type="show1 ? 'text' : 'password'" name="input-10-1" :hint="$t('users.password_length')" counter
                    @click:append="show1 = !show1" :label="$t('users.password_repeat')" required></v-text-field>

                  <v-flex class="text-center d-flex" align-center>
                    <v-spacer></v-spacer>
                    <v-checkbox v-model="agreement">
                      <template v-slot:label>
                        <v-flex>
                          {{ $t("users.i_agree") }}
                          <a :href="`https://rentprog.${locale == 'ru' ? 'ru' : 'com'
                                                      }/${$root.$i18n.locale}/agreement`" class="subtitle-2" target="_blank">{{
                                                        $t("users.agreement_space") }}</a>
                          {{ $t("users.i_agree_3") }}
                          <a :href="`https://rentprog.${locale == 'ru' ? 'ru' : 'com'
                                                      }/${$root.$i18n.locale}/privacy`" class="subtitle-2" target="_blank">{{
                                                        $t("users.i_agree_2") }}</a>
                        </v-flex>
                      </template>
                    </v-checkbox>
                    <v-flex> </v-flex>
                  </v-flex>
                  <v-flex class="text-center">
                    <v-btn @click="recaptcha()" color="success">{{
                                          $t("users.registrations")
                                          }}</v-btn>
                    <div class="mt-2">
                      <router-link to="/signin">{{
                                              $t("users.enter")
                                              }}</router-link>
                      <br />
                      <router-link to="/forgot_password">{{
                                              $t("users.password_lost")
                                              }}</router-link>
                    </div>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <v-overlay :value="showCreateDemo">
          <v-card>
            <v-card-text>
              {{ $t("custom.create_demo_content") }}
              <v-progress-linear class="mt-2" color="info accent-4" indeterminate rounded height="6"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-overlay>
      </v-main>
    </v-app>
  </span>
</template>

<script>
import { countries } from "./countries";
export default {
  name: "Signup",
  data() {
    return {
      user_name: null,
      phone: null,
      phone_error: false,
      phone_error_text: "",
      company_name: null,
      countries: countries,
      country: null,
      city: null,
      email: null,
      password: null,
      password_confirmation: null,
      time_zones: [
        { text: "(GMT-12) International Date Line West", value: "Etc/GMT+12" },
        { text: "(GMT-11) Midway Island", value: "Pacific/Midway" },
        { text: "(GMT-11) American Samoa", value: "Pacific/Pago_Pago" },
        { text: "(GMT-10) Hawaii", value: "Pacific/Honolulu" },
        { text: "(GMT-9) Alaska", value: "America/Juneau" },
        {
          text: "(GMT-8) Pacific Time (US & Canada)",
          value: "America/Los_Angeles",
        },
        { text: "(GMT-8) Tijuana", value: "America/Tijuana" },
        {
          text: "(GMT-7) Mountain Time (US & Canada)",
          value: "America/Denver",
        },
        { text: "(GMT-7) Arizona", value: "America/Phoenix" },
        { text: "(GMT-7) Chihuahua", value: "America/Chihuahua" },
        { text: "(GMT-7) Mazatlan", value: "America/Mazatlan" },
        {
          text: "(GMT-6) Central Time (US & Canada)",
          value: "America/Chicago",
        },
        { text: "(GMT-6) Saskatchewan", value: "America/Regina" },
        { text: "(GMT-6) Guadalajara", value: "America/Mexico_City" },
        { text: "(GMT-6) Mexico City", value: "America/Mexico_City" },
        { text: "(GMT-6) Monterrey", value: "America/Monterrey" },
        { text: "(GMT-6) Central America", value: "America/Guatemala" },
        {
          text: "(GMT-5) Eastern Time (US & Canada)",
          value: "America/New_York",
        },
        {
          text: "(GMT-5) Indiana (East)",
          value: "America/Indiana/Indianapolis",
        },
        { text: "(GMT-5) Bogota", value: "America/Bogota" },
        { text: "(GMT-5) Lima", value: "America/Lima" },
        { text: "(GMT-5) Quito", value: "America/Lima" },
        { text: "(GMT-4) Atlantic Time (Canada)", value: "America/Halifax" },
        { text: "(GMT-4) Caracas", value: "America/Caracas" },
        { text: "(GMT-4) La Paz", value: "America/La_Paz" },
        { text: "(GMT-4) Newfoundland", value: "America/St_Johns" },
        { text: "(GMT-4) Georgetown", value: "America/Guyana" },
        { text: "(GMT-4) Puerto Rico", value: "America/Puerto_Rico" },
        { text: "(GMT-3) Santiago", value: "America/Santiago" },
        { text: "(GMT-3) Brasilia", value: "America/Sao_Paulo" },
        {
          text: "(GMT-3) Buenos Aires",
          value: "America/Argentina/Buenos_Aires",
        },
        { text: "(GMT-3) Montevideo", value: "America/Montevideo" },
        { text: "(GMT-3) Greenland", value: "America/Godthab" },
        { text: "(GMT-2) Mid-Atlantic", value: "Atlantic/South_Georgia" },
        { text: "(GMT-1) Azores", value: "Atlantic/Azores" },
        { text: "(GMT-1) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
        { text: "(GMT+0) Dublin", value: "Europe/Dublin" },
        { text: "(GMT+0) Edinburgh", value: "Europe/London" },
        { text: "(GMT+0) Lisbon", value: "Europe/Lisbon" },
        { text: "(GMT+0) London", value: "Europe/London" },
        { text: "(GMT+0) Monrovia", value: "Africa/Monrovia" },
        { text: "(GMT+0) UTC", value: "UTC" },
        { text: "(GMT+1) Casablanca", value: "Africa/Casablanca" },
        { text: "(GMT+1) Belgrade", value: "Europe/Belgrade" },
        { text: "(GMT+1) Bratislava", value: "Europe/Bratislava" },
        { text: "(GMT+1) Budapest", value: "Europe/Budapest" },
        { text: "(GMT+1) Ljubljana", value: "Europe/Ljubljana" },
        { text: "(GMT+1) Prague", value: "Europe/Prague" },
        { text: "(GMT+1) Sarajevo", value: "Europe/Sarajevo" },
        { text: "(GMT+1) Skopje", value: "Europe/Skopje" },
        { text: "(GMT+1) Warsaw", value: "Europe/Warsaw" },
        { text: "(GMT+1) Zagreb", value: "Europe/Zagreb" },
        { text: "(GMT+1) Brussels", value: "Europe/Brussels" },
        { text: "(GMT+1) Copenhagen", value: "Europe/Copenhagen" },
        { text: "(GMT+1) Madrid", value: "Europe/Madrid" },
        { text: "(GMT+1) Paris", value: "Europe/Paris" },
        { text: "(GMT+1) Amsterdam", value: "Europe/Amsterdam" },
        { text: "(GMT+1) Berlin", value: "Europe/Berlin" },
        { text: "(GMT+1) Bern", value: "Europe/Zurich" },
        { text: "(GMT+1) Zurich", value: "Europe/Zurich" },
        { text: "(GMT+1) Rome", value: "Europe/Rome" },
        { text: "(GMT+1) Stockholm", value: "Europe/Stockholm" },
        { text: "(GMT+1) Vienna", value: "Europe/Vienna" },
        { text: "(GMT+1) West Central Africa", value: "Africa/Algiers" },
        { text: "(GMT+2) Bucharest", value: "Europe/Bucharest" },
        { text: "(GMT+2) Cairo", value: "Africa/Cairo" },
        { text: "(GMT+2) Helsinki", value: "Europe/Helsinki" },
        { text: "(GMT+2) Kyiv", value: "Europe/Kiev" },
        { text: "(GMT+2) Riga", value: "Europe/Riga" },
        { text: "(GMT+2) Sofia", value: "Europe/Sofia" },
        { text: "(GMT+2) Tallinn", value: "Europe/Tallinn" },
        { text: "(GMT+2) Vilnius", value: "Europe/Vilnius" },
        { text: "(GMT+2) Athens", value: "Europe/Athens" },
        { text: "(GMT+2) Jerusalem", value: "Asia/Jerusalem" },
        { text: "(GMT+2) Harare", value: "Africa/Harare" },
        { text: "(GMT+2) Pretoria", value: "Africa/Johannesburg" },
        { text: "(GMT+2) Kaliningrad", value: "Europe/Kaliningrad" },
        { text: "(GMT+3) Istanbul", value: "Europe/Istanbul" },
        { text: "(GMT+3) Minsk", value: "Europe/Minsk" },
        { text: "(GMT+3) Moscow", value: "Europe/Moscow" },
        { text: "(GMT+3) St. Petersburg", value: "Europe/Moscow" },
        { text: "(GMT+3) Volgograd", value: "Europe/Volgograd" },
        { text: "(GMT+3) Kuwait", value: "Asia/Kuwait" },
        { text: "(GMT+3) Riyadh", value: "Asia/Riyadh" },
        { text: "(GMT+3) Nairobi", value: "Africa/Nairobi" },
        { text: "(GMT+3) Baghdad", value: "Asia/Baghdad" },
        { text: "(GMT+3) Tehran", value: "Asia/Tehran" },
        { text: "(GMT+4) Samara", value: "Europe/Samara" },
        { text: "(GMT+4) Abu Dhabi", value: "Asia/Muscat" },
        { text: "(GMT+4) Muscat", value: "Asia/Muscat" },
        { text: "(GMT+4) Baku", value: "Asia/Baku" },
        { text: "(GMT+4) Tbilisi", value: "Asia/Tbilisi" },
        { text: "(GMT+4) Yerevan", value: "Asia/Yerevan" },
        { text: "(GMT+4) Kabul", value: "Asia/Kabul" },
        { text: "(GMT+5) Ekaterinburg", value: "Asia/Yekaterinburg" },
        { text: "(GMT+5) Islamabad", value: "Asia/Karachi" },
        { text: "(GMT+5) Karachi", value: "Asia/Karachi" },
        { text: "(GMT+5) Tashkent", value: "Asia/Tashkent" },
        { text: "(GMT+5) Chennai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kolkata", value: "Asia/Kolkata" },
        { text: "(GMT+5) Mumbai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kathmandu", value: "Asia/Kathmandu" },
        { text: "(GMT+5) New Delhi", value: "Asia/Kolkata" },
        { text: "(GMT+5) Sri Jayawardenepura", value: "Asia/Colombo" },
        { text: "(GMT+6) Astana", value: "Asia/Dhaka" },
        { text: "(GMT+6) Dhaka", value: "Asia/Dhaka" },
        { text: "(GMT+6) Almaty", value: "Asia/Almaty" },
        { text: "(GMT+6) Rangoon", value: "Asia/Rangoon" },
        { text: "(GMT+6) Urumqi", value: "Asia/Urumqi" },
        { text: "(GMT+7) Novosibirsk", value: "Asia/Novosibirsk" },
        { text: "(GMT+7) Bangkok", value: "Asia/Bangkok" },
        { text: "(GMT+7) Hanoi", value: "Asia/Bangkok" },
        { text: "(GMT+7) Jakarta", value: "Asia/Jakarta" },
        { text: "(GMT+7) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        { text: "(GMT+8) Beijing", value: "Asia/Shanghai" },
        { text: "(GMT+8) Chongqing", value: "Asia/Chongqing" },
        { text: "(GMT+8) Hong Kong", value: "Asia/Hong_Kong" },
        { text: "(GMT+8) Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
        { text: "(GMT+8) Singapore", value: "Asia/Singapore" },
        { text: "(GMT+8) Taipei", value: "Asia/Taipei" },
        { text: "(GMT+8) Perth", value: "Australia/Perth" },
        { text: "(GMT+8) Irkutsk", value: "Asia/Irkutsk" },
        { text: "(GMT+8) Ulaanbaatar", value: "Asia/Ulaanbaatar" },
        { text: "(GMT+9) Seoul", value: "Asia/Seoul" },
        { text: "(GMT+9) Osaka", value: "Asia/Tokyo" },
        { text: "(GMT+9) Sapporo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Tokyo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Yakutsk", value: "Asia/Yakutsk" },
        { text: "(GMT+9) Darwin", value: "Australia/Darwin" },
        { text: "(GMT+10) Adelaide", value: "Australia/Adelaide" },
        { text: "(GMT+10) Brisbane", value: "Australia/Brisbane" },
        { text: "(GMT+10) Vladivostok", value: "Asia/Vladivostok" },
        { text: "(GMT+10) Guam", value: "Pacific/Guam" },
        { text: "(GMT+10) Port Moresby", value: "Pacific/Port_Moresby" },
        { text: "(GMT+11) Canberra", value: "Australia/Melbourne" },
        { text: "(GMT+11) Melbourne", value: "Australia/Melbourne" },
        { text: "(GMT+11) Sydney", value: "Australia/Sydney" },
        { text: "(GMT+11) Hobart", value: "Australia/Hobart" },
        { text: "(GMT+11) Magadan", value: "Asia/Magadan" },
        { text: "(GMT+11) Srednekolymsk", value: "Asia/Srednekolymsk" },
        { text: "(GMT+11) Solomon Is.", value: "Pacific/Guadalcanal" },
        { text: "(GMT+11) New Caledonia", value: "Pacific/Noumea" },
        { text: "(GMT+12) Fiji", value: "Pacific/Fiji" },
        { text: "(GMT+12) Kamchatka", value: "Asia/Kamchatka" },
        { text: "(GMT+12) Marshall Is.", value: "Pacific/Majuro" },
        { text: "(GMT+13) Auckland", value: "Pacific/Auckland" },
        { text: "(GMT+13) Wellington", value: "Pacific/Auckland" },
        { text: "(GMT+13) Nuku'alofa", value: "Pacific/Tongatapu" },
        { text: "(GMT+13) Tokelau Is.", value: "Pacific/Fakaofo" },
        { text: "(GMT+13) Chatham Is.", value: "Pacific/Chatham" },
        { text: "(GMT+13) Samoa", value: "Pacific/Apia" },
      ],
      time_zone:
        this.$root.$i18n.locale == "ru" ? "Europe/Moscow" : "Europe/Lisbon",
      locales: [
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      locale: this.$root.$i18n.locale,
      showCreateDemo: false,
      show1: false,
      agreement: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    // this.checkSignedIn();
    if (this.$route.query.ref) {
      localStorage.setItem("referral_code", this.$route.query.ref);
    }
    console.log(localStorage.getItem('referral_code'))
  },
  updated() {
    // this.checkSignedIn();
  },
  watch: {
    locale() {
      this.$i18n.locale = this.locale;
      this.$root.$i18n.locale = this.locale;
      localStorage.setItem("lang", this.locale);
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
        .then(() => this.signup())
        .catch((error) => {
          this.setError(error, this.$t("errors.captcha"));
          console.log(error);
        });
    },
    selectSource(source) {
      // ['Неопределено', 'SEO', 'Контекст', 'Instagram', 'Telegram', 'Facebook', 'VseProkaty', 'VK', 'Whatsapp']
      switch (source) {
        // case 'seo':
        //   return 'SEO';
        //   break;
        case 'context':
          return 'Контекст';
          break;
        case 'instagram':
          return 'Instagram';
          break;
        case 'tg':
          return 'Telegram';
          break;
        case 'fb':
          return 'Facebook';
          break;
        case 'vp':
          return 'VseProkaty';
          break;
        case 'vk':
          return 'VK';
          break;
        case 'wa':
          return 'Whatsapp';
          break;
        case 'email':
          return 'Email';
          break;
        case 'crmindex':
          return 'CrmIndex';
          break;
        case 'picktech':
          return 'Picktech';
          break;
        case 'a2is':
          return 'A2is';
          break;
        case 'chat':
          return 'Chat';
          break;
        case 'phone':
          return 'Обзвон';
          break;
        default:
          return 'Партнерская программа';
          break;
      }
    },
    signup() {
      let self = this;
      if (
        this.email &&
        this.password &&
        this.password_confirmation &&
        this.password === this.password_confirmation &&
        this.user_name &&
        this.company_name &&
        (this.phone && this.phone.length > 5) &&
        this.country &&
        this.city &&
        this.agreement
      ) {
        this.isLoading = true;
        let data = {
          user: {
            name: self.user_name,
            phone: self.phone,
            email: self.email,
            password: self.password,
            password_confirmation: self.password_confirmation,
            encrypted_password_digest: self.password,
          },
          company_name: self.company_name,
          country: self.country.name,
          city: self.city,
          time_zone: self.time_zone,
          locale: self.locale,
          source: localStorage.getItem('referral_code') ? self.selectSource(localStorage.getItem('referral_code')) : 'Неопределено',
          referral_code: localStorage.getItem('referral_code') ? localStorage.getItem('referral_code') : null,
        };
        this.axios({
          url: "users",
          baseURL:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://rentprog.net",
          method: "post",
          data: data,
        })
          .then(() => {
            this.axios({
              url: "users/sign_in",
              baseURL:
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3000"
                  : "https://rentprog.net",
              method: "post",
              data: data,
            })
              .then((response) => {
                this.$store.commit("setUserInfo", response);
              })
              .then(() => {
                this.getUserInfo();
              })
              .catch((error) => {
                self.isLoading = false;
                self.$swal({
                  title: self.$t("errors.error"),
                  text: error,
                  icon: "error",
                });
                console.log(error);
              });
          })
          .catch((error) => {
            self.isLoading = false;
            if (error.response && error.response.status == 422) {
              this.$swal({
                title: this.$t("errors.user_exists_title"),
                text: this.$t("errors.user_exists_text"),
                toast: false,
                icon: "error",
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: this.$t("custom.ok"),
              });
              // self.setError(
              //   self.$t("errors.user_exists_title"),
              //   self.$t("errors.user_exists_text")
              // );
            } else {
              self.setError(error.message, self.$t("errors.registration"));
            }
            // this.$router.replace({ name: "Signin" });
          });
      } else if (
        this.email &&
        this.password &&
        this.password_confirmation &&
        this.password === this.password_confirmation &&
        this.user_name &&
        this.company_name &&
        (this.phone && this.phone.length > 5) &&
        this.country &&
        this.city &&
        this.agreement == false
      ) {
        this.$swal({
          title: this.$t("errors.agreement_title"),
          text: this.$t("errors.agreement_text"),
          icon: "error",
          showCancelButton: false,
        });
      } else if (
        this.email &&
        this.password &&
        this.password_confirmation &&
        this.user_name &&
        this.company_name &&
        (this.phone && this.phone.length > 5) &&
        this.agreement &&
        this.password != this.password_confirmation
      ) {
        this.$swal({
          title: this.$t("errors.pass_not_equal_title"),
          text: this.$t("errors.pass_not_equal_text"),
          icon: "error",
          showCancelButton: false,
        });
      } else {
        this.$swal({
          title: this.$t("errors.all_fields_title"),
          text: this.$t("errors.all_fields_text"),
          icon: "error",
          showCancelButton: false,
        });
      }
      // Проверка телефона
      if (this.phone && this.phone.length < 6) {
        this.phone_error = true;
        this.phone_error_text = this.$t("errors.phone_error");
      } else {
        this.phone_error = false;
        this.phone_error_text = "";
      }
    },
    getUserInfo() {
      let self = this;
      this.axios
        .get("/me.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data.active) {
            self.$store.commit("setCurrentUser", response.data);
            self.signinSuccessful();
          } else {
            self.isLoading = false;
            self.$swal({
              title: self.$t("errors.deactivation_title"),
              text: self.$t("errors.deactivation_text"),
              icon: "error",
              showCancelButton: true,
            });
          }
          // self.$router.replace({ name: "Home" });
        })
        .catch(function (error) {
          self.$store.commit("unsetCurrentUser");
          self.isLoading = false;
          self.$swal({
            title: self.$t("errors.error"),
            text: error,
            icon: "error",
          });
          // self.$router.push({ path: `/signin` }).catch(err => {});
          console.log(error);
        });
    },
    signinSuccessful() {
      let self = this;
      this.isLoading = true;
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: self.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.demo_content = true;
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get(`api/v1/main_companies/${response.data.main_company_id}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response_main_company) => {
              this.$store.commit("setMainCompany", response_main_company.data);
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.main_company_data"));
              console.log(error);
            });
        })
        .then(() => {
          self.axios
            .get(`api/v1/doc_templates/${null}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response_data) => {
              if (response_data.data) {
                self.$store.commit("setDocUrl", response_data.data.url);
              } else {
                let doc_link =
                  self.$i18n.locale == "ru"
                    ? "https://web.rentprog.ru/doc_example.docx"
                    : "https://web.rentprog.com/doc_example_en.docx";
                self.$store.commit("setEntityDocUrl", doc_link);
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });
          self.axios
            .get(`api/v1/doc_entity_templates/${null}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((doc_entity_response) => {
              // Если есть договор, то он, если нет, то по умолчанию шаблон
              if (doc_entity_response.data) {
                self.$store.commit(
                  "setEntityDocUrl",
                  doc_entity_response.data.url
                );
              } else {
                let doc_link =
                  self.$i18n.locale == "ru"
                    ? "https://web.rentprog.ru/entity_doc_example.docx"
                    : "https://web.rentprog.com/entity_doc_example_en.docx";
                self.$store.commit("setEntityDocUrl", doc_link);
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });
          self.axios
            .get(`api/v1/invoice_templates`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((invoice_response) => {
              // Если есть договор, то он, если нет, то по умолчанию шаблон
              if (invoice_response.data) {
                self.$store.commit("setInvoiceUrl", invoice_response.data.url);
              } else {
                self.$store.commit(
                  "setInvoiceUrl",
                  "https://web.rentprog.ru/invoice_example.docx"
                );
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });

          self.axios
            .get(`api/v1/messages`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((messages_response) => {
              self.$store.commit(
                "setMessages",
                messages_response.data.messages
              );
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.messages"));
              console.log(error);
            });
        })
        .catch((error) => {
          self.isLoading = false;
          self.setError(error, self.$t("errors.company_data"));
          console.log(error);
        })
        .finally(() => {
          self.isLoading = false;
          this.showCreateDemo = true;
          setTimeout(() => {
            this.showCreateDemo = false;
            this.$store.commit("setBannerRemoveDemoContent");
            self.$router.replace({ name: "Home" });
          }, 3000);
        });
    },
    phoneCode() {
      if (this.country && this.country.phone[0]) {
        this.phone = `+${this.country.phone[0]}`;
      }
    },
    setError(error, text) {
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
    },
  },
};
</script>

<style lang="css">
.form-signup {
  width: 70%;
  max-width: 500px;
  padding: 10% 15px;
  margin: 0 auto;
}
</style>
