<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <v-flex md12>
          <h2>{{ $t('custom.analitics') }}</h2>
        </v-flex> -->
    <v-container
      fluid
      fill-height
      v-resize="checkMobile"
      :style="isMobile ? 'padding: 5px !important;' : ''"
    >
      <!-- <v-layout> -->

      <v-toolbar
        v-if="!isMobile"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center start-tour-analitics-1"
      >
        <v-select
          v-model="selected_report_type"
          clearable
          :items="report_types"
          :label="$t('analitics.select_type')"
          class="mt-5"
        ></v-select>
        <v-menu
          v-model="start_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date_format"
              :label="$t('analitics.start_date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="mt-5"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            :locale="$i18n.locale"
            @input="start_date_menu = false"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="end_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date_format"
              :label="$t('analitics.end_date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="mt-5 ml-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            :locale="$i18n.locale"
            @input="end_date_menu = false"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-checkbox
          v-if="
            selected_report_type == $t('analitics.common_cars') ||
            selected_report_type == $t('analitics.common_company')
          "
          v-model="with_property"
          :label="$t('analitics.with_property')"
          class="mt-5"
        ></v-checkbox>
        <v-btn
          v-if="selected_report_type == $t('analitics.common_cars')"
          small
          color="success"
          class="ml-3"
          @click="createCarsReport()"
          >{{ $t("analitics.generate") }}</v-btn
        >
        <v-btn
          v-if="selected_report_type == $t('analitics.common_company')"
          small
          color="success"
          class="ml-3"
          @click="createCompanyReport()"
          >{{ $t("analitics.generate") }}</v-btn
        >
        <v-btn
          v-if="selected_report_type == $t('analitics.common_clients')"
          small
          color="success"
          class="ml-3"
          @click="createClientsReport()"
          >{{ $t("analitics.generate") }}</v-btn
        >
        <v-btn
          v-if="selected_report_type == $t('analitics.common_workers')"
          small
          color="success"
          class="ml-3"
          @click="createWorkersReport()"
          >{{ $t("analitics.generate") }}</v-btn
        >
        <v-btn
          v-if="selected_report_type == $t('analitics.common_bookings')"
          small
          color="success"
          class="ml-3"
          @click="createBookingsReport()"
          >{{ $t("analitics.generate") }}</v-btn
        >

        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/8/guides/25`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        v-else
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center start-tour-analitics-1"
        height="250px"
      >
        <v-flex md12>
          <v-flex md12 class="mt-3">
            <v-select
              v-model="selected_report_type"
              clearable
              hide-details
              :items="report_types"
              :label="$t('analitics.select_type')"
            ></v-select>
          </v-flex>
          <v-flex md12>
            <v-menu
              v-model="start_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_date_format"
                  :label="$t('analitics.start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  class="mt-5"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start_date"
                :locale="$i18n.locale"
                @input="start_date_menu = false"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md12>
            <v-menu
              v-model="end_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end_date_format"
                  :label="$t('analitics.end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                :locale="$i18n.locale"
                @input="end_date_menu = false"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md12>
            <v-checkbox
              v-if="
                selected_report_type == $t('analitics.common_cars') ||
                selected_report_type == $t('analitics.common_company')
              "
              v-model="with_property"
              hide-details
              :label="$t('analitics.with_property')"
              class="mt-5"
            ></v-checkbox>
          </v-flex>
          <v-flex md12 class="d-flex justify-center align-center">
            <v-btn
              v-if="selected_report_type == $t('analitics.common_cars')"
              small
              color="success"
              class=""
              @click="createCarsReport()"
              >{{ $t("analitics.generate") }}</v-btn
            >
            <v-btn
              v-if="selected_report_type == $t('analitics.common_company')"
              small
              color="success"
              class=""
              @click="createCompanyReport()"
              >{{ $t("analitics.generate") }}</v-btn
            >
            <v-btn
              v-if="selected_report_type == $t('analitics.common_clients')"
              small
              color="success"
              class=""
              @click="createClientsReport()"
              >{{ $t("analitics.generate") }}</v-btn
            >
            <v-btn
              v-if="selected_report_type == $t('analitics.common_workers')"
              small
              color="success"
              class=""
              @click="createWorkersReport()"
              >{{ $t("analitics.generate") }}</v-btn
            >
            <v-btn
              v-if="selected_report_type == $t('analitics.common_bookings')"
              small
              color="success"
              class=""
              @click="createBookingsReport()"
              >{{ $t("analitics.generate") }}</v-btn
            >

            <v-btn
              v-if="!isIos"
              icon
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/categories/8/guides/25`"
              target="_blank"
            >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <!-- По компании -->
      <v-flex md12 class="mt-2">
        <h3>{{ $t("analitics.company_report_name") }}</h3>
        <v-data-table
          :headers="company_reports_headers"
          :items="company_reports"
          :search="search_company_reports"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          :class="!isMobile ? '' : 'is-mobile'"
          :sort-by="['id']"
          :sort-desc="[true]"
          :items-per-page="5"
          mobile-breakpoint="100"
          dense
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr
              class=""
              @dblclick="replaceToCompanyReport(props.item)"
              style="cursor: pointer"
            >
              <td class="text-center">{{ props.item.id }}</td>
              <td class="text-center">
                <v-icon
                  v-if="props.item.state == 'created'"
                  class="ml-2"
                  style="cursor: pointer"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  style="cursor: pointer"
                  color="info"
                >
                  mdi-timer-sand
                </v-icon>
              </td>
              <td class="text-center">
                {{ formatedDate(props.item.start_date) }}
              </td>
              <td class="text-center">
                {{ formatedDate(props.item.end_date) }}
              </td>
              <td class="text-center">
                {{ parseFloat(props.item.income).toFixed(2) }}
              </td>
              <td class="text-center">
                {{ parseFloat(props.item.expense).toFixed(2) }}
              </td>
              <td class="text-center">
                <v-icon color="info" v-if="props.item.with_property">
                  mdi-check
                </v-icon>
                <v-icon color="secondery" v-else> mdi-minus </v-icon>
              </td>
              <td class="text-center">
                <v-flex row class="justify-center align-middle">
                  <v-flex class="ml-4 text-right">
                    <v-icon
                      @click="replaceToCompanyReport(props.item)"
                      class="mr-2"
                      style="cursor: pointer"
                      color="info"
                      id="report_for_start_tour"
                    >
                      mdi-eye-outline
                    </v-icon>
                  </v-flex>
                  <v-flex class="text-center">
                    <v-icon
                      v-if="props.item.state == 'created'"
                      @click="deleteCompanyReport(props.item)"
                      class=""
                      color="warning"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </v-flex>
                  <!-- <v-flex class="text-center">
                          <download-excel class="m-0 p-0" v-if="props.item.state == 'created'" :data="[props.item]">
                            <v-icon class="" style="cursor:pointer;" color="success"> mdi-file-excel-outline </v-icon>
                          </download-excel>
                        </v-flex> -->
                </v-flex>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{ $t("tables.no_search_result", { msg: search_company_reports }) }}
          </v-alert>
        </v-data-table>
      </v-flex>
      <v-flex md12 class="mt-2">
        <v-divider></v-divider>
      </v-flex>
      <!-- По клиентам -->
      <v-flex md12 class="mt-2">
        <v-card-text>
          <h2>{{ $t("analitics.for_clients") }}</h2>
          <v-data-table
            :headers="clients_reports_headers"
            :items="clients_reports"
            :search="search_clients_reports"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="5"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr
                class=""
                @dblclick="replaceToClientsReport(props.item)"
                style="cursor: pointer"
              >
                <td class="text-center">{{ props.item.id }}</td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.state == 'created'"
                    class="ml-2"
                    style="cursor: pointer"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    class="ml-2"
                    style="cursor: pointer"
                    color="info"
                  >
                    mdi-timer-sand
                  </v-icon>
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.start_date) }}
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.end_date) }}
                </td>
                <td class="text-center">
                  {{ parseFloat(props.item.convertion).toFixed(2) }}
                </td>
                <td class="text-center">{{ props.item.bookings_mileage }}</td>
                <td class="text-center">{{ props.item.bookings_days }}</td>
                <td class="text-center">
                  <v-flex row class="justify-center align-middle">
                    <v-flex class="ml-4 text-right">
                      <v-icon
                        @click="replaceToClientsReport(props.item)"
                        class="mr-2"
                        style="cursor: pointer"
                        color="info"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </v-flex>
                    <v-flex class="text-center">
                      <v-icon
                        v-if="props.item.state == 'created'"
                        @click="deleteClientsReport(props.item)"
                        class=""
                        color="warning"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </v-flex>
                    <!-- <v-flex class="text-left">
                            <download-excel v-if="props.item.state == 'created'" :data="[props.item]">
                              <v-icon class="ml-2" style="cursor:pointer;" color="success"> mdi-file-excel-outline </v-icon>
                            </download-excel>
                          </v-flex> -->
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{
                $t("tables.no_search_result", { msg: search_clients_reports })
              }}
            </v-alert>
          </v-data-table>
        </v-card-text>
      </v-flex>
      <v-flex md12 class="mt-2">
        <v-divider></v-divider>
      </v-flex>
      <!-- По машинам -->
      <v-flex md12 class="mt-2">
        <v-card-text>
          <h2>{{ $t("analitics.for_cars") }}</h2>
          <v-data-table
            :headers="cars_reports_headers"
            :items="cars_reports"
            :search="search_cars_reports"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :class="!isMobile ? '' : 'is-mobile'"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="5"
            mobile-breakpoint="100"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr
                class=""
                @dblclick="replaceToCarsReport(props.item)"
                style="cursor: pointer"
              >
                <td class="text-center">{{ props.item.id }}</td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.state == 'created'"
                    class="ml-2"
                    style="cursor: pointer"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    class="ml-2"
                    style="cursor: pointer"
                    color="info"
                  >
                    mdi-timer-sand
                  </v-icon>
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.start_date) }}
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.end_date) }}
                </td>
                <td class="text-center">
                  <v-icon color="info" v-if="props.item.with_property">
                    mdi-check
                  </v-icon>
                  <v-icon color="secondery" v-else> mdi-minus </v-icon>
                </td>
                <td class="text-center">
                  <v-flex row class="justify-center align-middle">
                    <v-flex class="text-right">
                      <v-icon
                        @click="replaceToCarsReport(props.item)"
                        class="mr-2"
                        style="cursor: pointer"
                        color="info"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </v-flex>
                    <v-flex class="text-center">
                      <v-icon
                        v-if="props.item.state == 'created'"
                        @click="deleteCarsReport(props.item)"
                        class=""
                        color="warning"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </v-flex>
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{ $t("tables.no_search_result", { msg: search_cars_reports }) }}
            </v-alert>
          </v-data-table>
        </v-card-text>
      </v-flex>
      <v-flex md12 class="mt-2">
        <v-divider></v-divider>
      </v-flex>
      <!-- По персоналу -->
      <v-flex md12 class="mt-2">
        <v-card-text>
          <h2>{{ $t("analitics.for_workers") }}</h2>
          <v-data-table
            :headers="workers_reports_headers"
            :items="workers_reports"
            :search="search_workers_reports"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="5"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr
                class=""
                @dblclick="replaceToWorkersReport(props.item)"
                style="cursor: pointer"
              >
                <td class="text-center">{{ props.item.id }}</td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.state == 'created'"
                    class="ml-2"
                    style="cursor: pointer"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    class="ml-2"
                    style="cursor: pointer"
                    color="info"
                  >
                    mdi-timer-sand
                  </v-icon>
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.start_date) }}
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.end_date) }}
                </td>
                <td class="text-center">
                  <v-flex row class="justify-center align-middle">
                    <v-flex class="text-right">
                      <v-icon
                        @click="replaceToWorkersReport(props.item)"
                        class="mr-2"
                        style="cursor: pointer"
                        color="info"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </v-flex>
                    <v-flex class="text-center">
                      <v-icon
                        v-if="props.item.state == 'created'"
                        @click="deleteWorkersReport(props.item)"
                        class=""
                        color="warning"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </v-flex>
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{
                $t("tables.no_search_result", { msg: search_workers_reports })
              }}
            </v-alert>
          </v-data-table>
        </v-card-text>
      </v-flex>
      <!-- По броням -->
      <v-flex md12 class="mt-2">
        <v-card-text>
          <h2>{{ $t("analitics.for_bookings") }}</h2>
          <v-data-table
            :headers="bookings_reports_headers"
            :items="bookings_reports"
            :search="search_bookings_reports"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="5"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr
                class=""
                @dblclick="replaceToBookingsReport(props.item)"
                style="cursor: pointer"
              >
                <td class="text-center">{{ props.item.id }}</td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.state == 'created'"
                    class="ml-2"
                    style="cursor: pointer"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    class="ml-2"
                    style="cursor: pointer"
                    color="info"
                  >
                    mdi-timer-sand
                  </v-icon>
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.start_date) }}
                </td>
                <td class="text-center">
                  {{ formatedDate(props.item.end_date) }}
                </td>
                <td class="text-center">
                  <v-flex row class="justify-center align-middle">
                    <v-flex class="text-right">
                      <v-icon
                        @click="replaceToBookingsReport(props.item)"
                        class="mr-2"
                        style="cursor: pointer"
                        color="info"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </v-flex>
                    <v-flex class="text-center">
                      <v-icon
                        v-if="props.item.state == 'created'"
                        @click="deleteBookingsReport(props.item)"
                        class=""
                        color="warning"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </v-flex>
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{
                $t("tables.no_search_result", { msg: search_bookings_reports })
              }}
            </v-alert>
          </v-data-table>
        </v-card-text>
      </v-flex>
      <!-- </v-layout> -->
    </v-container>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { GChart } from "vue-google-charts";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      analitics_data: [],
      filtered_analitics_data: [],
      company_reports: [],
      clients_reports: [],
      workers_reports: [],
      bookings_reports: [],
      cars_reports: [],
      with_property: false,
      cars_codes: [],
      selected_cars: [],
      clients_data: {
        clients_count: null,
        active_clients_count: null,
        ads_rub: null,
        ltv_value: null,
        bookings_mileage: null,
      },
      start_date: null,
      end_date: null,
      property: false,
      headers: [
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        {
          text: this.$t("analitics.incomes"),
          value: "income_rub",
          align: "center",
        },
        {
          text: this.$t("analitics.expences"),
          value: "expences_rub",
          align: "center",
        },
        { text: this.$t("analitics.profit"), value: "profit", align: "center" },
        {
          text: this.$t("analitics.average_profit"),
          value: "average_profit",
          align: "center",
        },
        {
          text: this.$t("analitics.average_month_profit"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.investment_100k"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.work_days"),
          value: "work_days",
          align: "center",
        },
        {
          text: this.$t("analitics.days_in_rent"),
          value: "bookings_days",
          align: "center",
        },
        {
          text: this.$t("analitics.percent_loading"),
          value: "loading_percentage",
          align: "center",
        },
        {
          text: this.$t("analitics.bookings_mileage"),
          value: "bookings_mileage",
          align: "center",
        },
        {
          text: this.$t("analitics.average_month_mileage"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.km_cost"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.km_income"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.average_count"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.with_car"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.with_car_km"),
          sortable: false,
          align: "center",
        },
      ],
      company_reports_headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("analitics.incomes"),
          value: "income",
          align: "center",
        },
        {
          text: this.$t("analitics.expences"),
          value: "expense",
          align: "center",
        },
        {
          text: this.$t("analitics.with_property_short"),
          value: "with_property",
          align: "center",
        },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
      ],
      clients_reports_headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("analitics.convertion"),
          value: "convertion",
          align: "center",
        },
        {
          text: this.$t("analitics.bookings_mileage"),
          value: "bookings_mileage",
          align: "center",
        },
        {
          text: this.$t("analitics.days_in_rent"),
          value: "bookings_days",
          align: "center",
        },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
      ],
      cars_reports_headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("analitics.with_property_short"),
          value: "with_property",
          align: "center",
        },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
      ],
      workers_reports_headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
      ],
      bookings_reports_headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
      ],
      search: null,
      search_company_reports: null,
      search_clients_reports: null,
      search_workers_reports: null,
      search_cars_reports: null,
      search_bookings_reports: null,
      start_date_format: null,
      end_date_format: null,
      selected_report_type: this.$t("analitics.common_company"),
      revenue: 0,
      expences: 0,
      income: 0,
      revenue_for_period_data: [],
      counts_groups: [],
      report_types: [
        this.$t("analitics.common_company"),
        this.$t("analitics.common_cars"),
        this.$t("analitics.common_clients"),
        this.$t("analitics.common_workers"),
        this.$t("analitics.common_bookings"),
      ],
      pie_chart_data: [],
      pieIncomeOptions: {
        title: this.$t("custom.income"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pieExpencesOptions: {
        title: this.$t("custom.expences"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      showExcel: false,
      start_date_menu: false,
      end_date_menu: false,
      showRevenueGraf: false,
      showRevenueColumn: false,
      showRevenueTable: false,
      showClientsData: false,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.removeData();
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/company_reports`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.company_reports = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_report_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/clients_reports`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.clients_reports = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.clients_report_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/cars_reports`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars_reports = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_report_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/workers_reports`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers_reports = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.workers_report_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/bookings_reports`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.bookings_reports = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.bookings_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => {});
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  watch: {
    start_date() {
      if (this.start_date) {
        this.start_date_format = moment(this.start_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      }
    },
    end_date() {
      if (this.end_date) {
        this.end_date_format = moment(this.end_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      }
    },
    selected_cars() {
      let self = this;
      if (this.selected_cars.length > 0) {
        this.filtered_analitics_data = this.analitics_data.filter((car) =>
          self.selected_cars.includes(car.car_code)
        );
      } else {
        this.filtered_analitics_data = [];
        this.analitics_data.forEach((car) =>
          self.filtered_analitics_data.push(car)
        );
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    createCompanyReport() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/company_reports`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
              with_property: this.with_property,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.company_reports.push(response.data);
            this.start_date = null;
            this.end_date = null;
            this.replaceToCompanyReport(response.data);
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.select_dates"),
          text: this.error,
        });
      }
    },
    createClientsReport() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/clients_reports`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.clients_reports.push(response.data);
            this.start_date = null;
            this.end_date = null;
            this.replaceToClientsReport(response.data);
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.select_dates"),
          text: this.error,
        });
      }
    },
    createCarsReport() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/cars_reports`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
              with_property: this.with_property,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.cars_reports.push(response.data);
            this.start_date = null;
            this.end_date = null;
            this.replaceToCarsReport(response.data);
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.select_dates"),
          text: this.error,
        });
      }
    },
    createWorkersReport() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/workers_reports`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.workers_reports.push(response.data);
            this.start_date = null;
            this.end_date = null;
            this.replaceToWorkersReport(response.data);
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.select_dates"),
          text: this.error,
        });
      }
    },
    createBookingsReport() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/bookings_reports`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.bookings_reports.push(response.data);
            this.start_date = null;
            this.end_date = null;
            this.replaceToBookingsReport(response.data);
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.select_dates"),
          text: this.error,
        });
      }
    },
    deleteCompanyReport(report) {
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/company_reports/${report.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then(() => {
              this.company_reports.splice(
                this.company_reports.indexOf(report),
                1
              );
              this.$swal({
                title: this.$t("custom.deleted"),
                icon: "success",
              });
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.delete_report"))
            )
            .finally(() => (this.isLoading = false));
        }
      });
    },
    deleteCarsReport(report) {
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/cars_reports/${report.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then(() => {
              this.cars_reports.splice(this.cars_reports.indexOf(report), 1);
              this.$swal({
                title: this.$t("custom.deleted"),
                icon: "success",
              });
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.delete_report"))
            )
            .finally(() => (this.isLoading = false));
        }
      });
    },
    deleteWorkersReport(report) {
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/workers_reports/${report.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then(() => {
              this.workers_reports.splice(
                this.workers_reports.indexOf(report),
                1
              );
              this.$swal({
                title: this.$t("custom.deleted"),
                icon: "success",
              });
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.delete_report"))
            )
            .finally(() => (this.isLoading = false));
        }
      });
    },
    deleteBookingsReport(report) {
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/bookings_reports/${report.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then(() => {
              this.bookings_reports.splice(
                this.bookings_reports.indexOf(report),
                1
              );
              this.$swal({
                title: this.$t("custom.deleted"),
                icon: "success",
              });
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.delete_report"))
            )
            .finally(() => (this.isLoading = false));
        }
      });
    },
    deleteClientsReport(report) {
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/clients_reports/${report.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then(() => {
              this.clients_reports.splice(
                this.clients_reports.indexOf(report),
                1
              );
              this.$swal({
                title: this.$t("custom.deleted"),
                icon: "success",
              });
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.delete_report"))
            )
            .finally(() => (this.isLoading = false));
        }
      });
    },
    removeData() {
      (this.selected_report_type = this.$t("analitics.common_company")),
        (this.revenue = 0);
      this.expences = 0;
      this.income = 0;
      this.start_date = null;
      this.end_date = null;
      (this.start_date_format = null),
        (this.end_date_format = null),
        (this.revenue_for_period_data = []);
      this.showRevenueColumn = false;
      this.showRevenueGraf = false;
      this.analitics_data = [];
      this.filtered_analitics_data = [];
      this.counts = [];
      this.showRevenueTable = false;
      this.showExcel = false;
      this.property = false;
      this.showClientsData = false;
      this.clients_data = {
        clients_count: null,
        active_clients_count: null,
        ads_rub: null,
        ltv_value: null,
        bookings_mileage: null,
      };
    },
    replaceToCompanyReport(report) {
      this.$router.push({ path: `/company_reports/${report.id}` });
    },
    replaceToClientsReport(report) {
      this.$router.push({ path: `/clients_reports/${report.id}` });
    },
    replaceToCarsReport(report) {
      this.$router.push({ path: `/cars_reports/${report.id}` });
    },
    replaceToWorkersReport(report) {
      this.$router.push({ path: `/workers_reports/${report.id}` });
    },
    replaceToBookingsReport(report) {
      this.$router.push({ path: `/bookings_reports/${report.id}` });
    },
    formatedDate(date) {
      return moment(date).format("ll");
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => {});
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    GChart,
  },
};
</script>
<style>
  .is-mobile .v-data-footer__select {
    margin-right: 0 !important;
  }
  .is-mobile .v-data-footer__icons-before {
    margin: 0 auto !important;
  }
  .is-mobile .v-data-footer__icons-after {
    margin: 0 auto !important;
  }
</style>
