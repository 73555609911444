<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title v-if="!show_kits">
        <v-flex
          md12
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between '
              : 'd-flex align-center justify-space-start'
          "
        >
          {{ $t("store.store") }}
          <v-switch
            v-model="show_only_in_stock"
            :class="isMobile ? 'mt-0 ' : 'mt-0 ml-3'"
            :label="$t('store.in_stock')"
            dense
            hide-details
          ></v-switch>
        </v-flex>
        <v-flex md2 class="px-2">
          <v-text-field
            v-model="search_products"
            append-icon="mdi-magnify"
            :label="$t('custom.search')"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-flex>
        <v-flex md2 class="px-2">
          <v-autocomplete
            v-model="filter_group"
            hide-no-data
            hide-details
            :label="$t('store.group_filter')"
            :items="products_groups"
            clearable
          >
          </v-autocomplete>
        </v-flex>
        <v-flex md2 class="px-2">
          <v-autocomplete
            v-model="filter_cars"
            hide-no-data
            hide-details
            :label="$t('store.group_cars')"
            :items="cars"
            item-text="code"
            item-value="id"
            clearable
            multiple
          >
          </v-autocomplete>
        </v-flex>
        <v-flex
          md4
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between mt-3'
              : 'd-flex align-center justify-end mt-3'
          "
        >
          <v-btn
            class="mr-2"
            small
            color="success"
            @click="(new_product_dialog = true), getCars()"
            v-tooltip="{
              content: $t('store.create_title'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.name") }}
            <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            small
            color="secodary"
            @click="$store.commit('setStoreKits'), getKits()"
            v-tooltip="{
              content: $t('store.kits'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.kits") }}
            <v-icon class="ml-1"> mdi-clipboard-list-outline </v-icon>
          </v-btn>
        </v-flex>
        <v-flex
          md2
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between mt-3'
              : 'd-flex align-center justify-space-between mt-3'
          "
        >
          <v-btn
            class="mr-2"
            small
            color="primary"
            @click="locations_edit_dialog = true"
            v-tooltip="{
              content: $t('store.stores'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.stores") }}
            <v-icon class="ml-1"> mdi-garage-lock </v-icon>
          </v-btn>
          <v-icon
            @click="scanner_dialog = true"
            class="mx-2"
            v-tooltip="{
              content: $t('store.scan_barcode'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            mdi-barcode-scan
          </v-icon>
          <download-excel
            :data="products"
            v-if="products && products.length > 0"
          >
            <v-icon class="ml-2" style="cursor: pointer" color="success">
              mdi-file-excel-outline
            </v-icon>
          </download-excel>
        </v-flex>
      </v-card-title>
      <v-card-title v-else>
        <v-flex
          md12
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between '
              : 'd-flex align-center justify-space-start'
          "
        >
          {{ $t("store.store") }}
          <v-switch
            v-model="show_only_in_stock"
            :class="isMobile ? 'mt-0 ' : 'mt-0 ml-3'"
            :label="$t('store.in_stock')"
            dense
            hide-details
          ></v-switch>
        </v-flex>
        <v-flex md3 class="px-2">
          <v-text-field
            v-model="search_kits"
            append-icon="mdi-magnify"
            :label="$t('custom.search')"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-flex>
        <v-flex md3 class="px-2">
          <v-autocomplete
            v-model="filter_group"
            hide-no-data
            hide-details
            :label="$t('store.group_filter')"
            :items="kits_groups"
            clearable
          >
          </v-autocomplete>
        </v-flex>
        <v-flex
          md4
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between mt-3'
              : 'd-flex align-center justify-end mt-3'
          "
        >
          <v-btn
            class="mr-2"
            small
            color="success"
            @click="getCars(), (new_kit_dialog = true)"
            v-tooltip="{
              content: $t('store.kit_create_title'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.kit") }}
            <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            small
            color="secodery"
            @click="$store.commit('setStoreKits')"
            v-tooltip="{
              content: $t('store.names'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.names") }}
            <v-icon class="ml-1"> mdi-basket-outline </v-icon>
          </v-btn>
        </v-flex>
        <v-flex
          md2
          :class="
            isMobile
              ? 'd-flex align-center justify-space-between mt-3'
              : 'd-flex align-center justify-space-between mt-3'
          "
        >
          <v-btn
            class="mr-2"
            small
            color="primary"
            @click="locations_edit_dialog = true"
            v-tooltip="{
              content: $t('store.stores'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            {{ $t("store.stores") }}
            <v-icon class="ml-1"> mdi-garage-lock </v-icon>
          </v-btn>
          <v-icon
            @click="scanner_dialog = true"
            class="mx-2"
            v-tooltip="{
              content: $t('store.scan_barcode'),
              placement: 'bottom-center',
              classes: ['black--text', 'white'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            mdi-barcode-scan
          </v-icon>
          <download-excel :data="kits" v-if="kits && kits.length > 0">
            <v-icon class="ml-2" style="cursor: pointer" color="success">
              mdi-file-excel-outline
            </v-icon>
          </download-excel>
        </v-flex>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-data-table
          v-if="!show_kits"
          :headers="products_headers"
          :items="filter_products_groups()"
          :search="search_products"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          sort-by="id"
          :sort-desc="true"
          item-key="id"
          :items-per-page="10"
          mobile-breakpoint="100"
          :class="!isMobile ? '' : 'is-mobile'"
          dense
          :expanded.sync="expanded_products"
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr
              :class="
                props.item.stock_balance > 0 ? '' : 'grey--text lighten-5'
              "
            >
              <td class="text-center" :key="props.item.id">
                {{ props.item.id }}
              </td>
              <td class="text-center">
                {{ returnDate(props.item.created_at) }}
              </td>
              <td class="text-center">
                {{ props.item.name }}
              </td>
              <td class="text-center">
                {{ props.item.group }}
              </td>
              <td class="text-center">
                {{ props.item.stock_balance }}
                {{ localeUnits(props.item.unit) }}
              </td>
              <td class="text-center">
                {{ props.item.avarege_price }}
              </td>
              <td class="text-center">
                {{ props.item.sku }}
              </td>
              <td class="text-center">
                {{ props.item.article }}
              </td>
              <td class="text-center">
                {{ props.item.store_place }}
              </td>
              <td
                class="text-center"
                @dblclick="saveBarcode(props.item.barcode)"
                style="cursor: pointer"
                v-tooltip="{
                  content: $t('store.save_barcode'),
                  placement: 'bottom-center',
                  classes: ['black--text', 'white'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 1000,
                    hide: 500,
                  },
                }"
              >
                <barcode
                  v-if="props.item.barcode"
                  :value="props.item.barcode"
                  width="1"
                  height="25"
                  font-size="10"
                  :id="props.item.barcode"
                  class="barcode"
                >
                  Error
                </barcode>
              </td>
              <td class="text-truncate" style="max-width: 200px">
                {{ props.item.description }}
              </td>
              <td class="text-center" nowrap>
                <v-icon
                  @click="
                    new_arrival_dialog = true;
                    (arrival = props.item),
                      (arrival.quantity = 1),
                      (arrival.price = props.item.price),
                      (arrival.stock_balance = props.item.stock_balance),
                      (arrival.id = props.item.id),
                      (arrival.description = '');
                  "
                  class="mr-1"
                  style="cursor: pointer"
                  color="success"
                  v-tooltip="{
                    content: $t('store.arrival'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-arrow-down-bold-box-outline
                </v-icon>
                <v-icon
                  @click="
                    getCars(),
                      (new_departure_dialog = true),
                      (departure.quantity = 1),
                      (departure.price = 0),
                      (departure.stock_balance = props.item.stock_balance),
                      (departure.id = props.item.id),
                      (departure.description = '')
                  "
                  :disabled="props.item.stock_balance <= 0"
                  class="mr-1"
                  style="cursor: pointer"
                  :color="props.item.stock_balance > 0 ? 'primary' : 'grey'"
                  v-tooltip="{
                    content: $t('store.departure'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-arrow-up-bold-box-outline
                </v-icon>
                <v-icon
                  @click="getCars(), editProduct(props.item)"
                  class="mr-1"
                  style="cursor: pointer"
                  v-tooltip="{
                    content: $t('custom.edit'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon
                  @click="deleteProduct(props.item)"
                  class="mr-1"
                  color="error"
                >
                  mdi-delete-outline
                </v-icon>
                <v-icon
                  v-if="notFindInExpendedProducts(props.item)"
                  @click="getProductFullInfo(props.item)"
                  class="mr-1"
                  v-tooltip="{
                    content: $t('gdrp.more'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-chevron-down
                </v-icon>
                <v-icon
                  v-else
                  @click="removeFromExtendedProducts(props.item)"
                  class=""
                >
                  mdi-chevron-up
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("store.store") }}</th>
                      <th class="text-center">
                        {{ $t("store.stock_balance") }}
                      </th>
                      <th class="text-left">{{ $t("store.movements") }}</th>
                      <th class="text-center">{{ $t("custom.control") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="location in locations" :key="location.name">
                      <td class="text-center">{{ location.name }}</td>
                      <td class="text-center">
                        {{ quantityInLocation(item, location) }}
                      </td>
                      <td>
                        <p
                          v-for="(item, i) in stocks_in_location(
                            item,
                            location
                          )"
                          :key="i"
                        >
                          <v-icon
                            v-if="item.type == 'arrival'"
                            class="mx-1"
                            color="success"
                          >
                            mdi-arrow-down-bold-box-outline
                          </v-icon>
                          <v-icon v-else class="mx-1" color="primary">
                            mdi-arrow-up-bold-box-outline
                          </v-icon>
                          {{ returnDateTime(item.created_at) }} |
                          {{ findWorker(item.user_id) }} |
                          {{ $t("store.price") }}:
                          {{ item.price ? item.price : 0 }} |
                          {{ $t("store.qty") }}: {{ item.quantity }} |
                          {{ item.description }}
                        </p>
                      </td>
                      <td class="text-center">
                        <v-icon
                          @click="
                            movement_dialog = true;
                            (movement.store_product_id = item.id),
                              (movement.location_id = location.id),
                              (movement.location_from_id = location.id);
                          "
                          :disabled="quantityInLocation(item, location) <= 0"
                          class="mx-1"
                          :color="
                            quantityInLocation(item, location) > 0
                              ? 'success'
                              : 'grey'
                          "
                          v-tooltip="{
                            content: $t('store.movements_tooltip'),
                            placement: 'bottom-center',
                            classes: ['black--text', 'white'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-swap-vertical-bold
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{
              $t("tables.no_search_result", {
                msg: search_products,
              })
            }}
          </v-alert>
        </v-data-table>
        <v-data-table
          v-else
          :headers="kits_headers"
          :items="filter_kits_groups()"
          :search="search_kits"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          sort-by="id"
          :sort-desc="true"
          item-key="id"
          :items-per-page="10"
          mobile-breakpoint="100"
          :expanded.sync="expanded_kits"
          :class="!isMobile ? '' : 'is-mobile'"
          dense
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center" :key="props.item.id">
                {{ props.item.id }}
              </td>
              <td class="text-center">
                {{ returnDate(props.item.created_at) }}
              </td>
              <td class="text-center">
                {{ props.item.name }}
              </td>
              <td class="text-center">
                {{ props.item.group }}
              </td>
              <td class="text-center">
                {{ calculateKitsStock(props.item) }}
              </td>
              <td class="text-center">
                {{ props.item.article }}
              </td>
              <td class="text-center">
                {{ props.item.store_place }}
              </td>
              <td
                class="text-center"
                @dblclick="saveBarcode(props.item.barcode)"
                style="cursor: pointer"
                v-tooltip="{
                  content: $t('store.save_barcode'),
                  placement: 'bottom-center',
                  classes: ['black--text', 'white'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 1000,
                    hide: 500,
                  },
                }"
              >
                <!-- {{ props.item.barcode }} -->
                <barcode
                  v-if="props.item.barcode"
                  :value="props.item.barcode"
                  width="1"
                  height="25"
                  font-size="10"
                  :id="props.item.barcode"
                >
                  Error
                </barcode>
              </td>
              <td class="text-truncate" style="max-width: 200px">
                {{ props.item.description }}
              </td>
              <td class="text-center">
                <v-icon
                  @click="
                    getCars(),
                      (new_departure_kit_dialog = true),
                      (departure_kit = props.item),
                      (departure_kit.kits_quantity = 1)
                  "
                  :disabled="calculateKitsStock(props.item) <= 0"
                  class="mr-1"
                  style="cursor: pointer"
                  :color="
                    calculateKitsStock(props.item) > 0 ? 'primary' : 'grey'
                  "
                  v-tooltip="{
                    content: $t('store.departure'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-arrow-up-bold-box-outline
                </v-icon>
                <v-icon
                  @click="editKit(props.item), getCars()"
                  class="mr-1"
                  style="cursor: pointer"
                  v-tooltip="{
                    content: $t('custom.edit'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon
                  @click="deleteKit(props.item)"
                  class="mr-1"
                  color="error"
                >
                  mdi-delete-outline
                </v-icon>
                <v-icon
                  v-if="notFindInExpendedKits(props.item)"
                  @click="expanded_kits.push(props.item)"
                  class="mr-1"
                  v-tooltip="{
                    content: $t('gdrp.more'),
                    placement: 'bottom-center',
                    classes: ['black--text', 'white'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-chevron-down
                </v-icon>
                <v-icon
                  v-else
                  @click="removeFromExtendedKits(props.item)"
                  class=""
                >
                  mdi-chevron-up
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("store.name") }}</th>
                      <th class="text-center">
                        {{ $t("store.quantity") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="store_kits_product in item.store_kits_products"
                      :key="store_kits_product.id"
                    >
                      <td class="text-center">
                        {{
                          findProductName(store_kits_product.store_product_id)
                        }}
                      </td>
                      <td class="text-center">
                        {{ store_kits_product.quantity }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{
              $t("tables.no_search_result", {
                msg: search_kits,
              })
            }}
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- Create Product -->
    <v-dialog
      v-model="new_product_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.create_title") }}</span>
          <v-icon class="ml-2 green--text" @click="productSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="new_product_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.name"
                    :label="$t('store.name')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-combobox
                    v-model="product.group"
                    hide-no-data
                    hide-details
                    :label="$t('store.group_search')"
                    :items="products_groups"
                  >
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.sku"
                    :label="$t('store.sku')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.store_place"
                    :label="$t('store.store_place')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="product.unit"
                    :items="units"
                    item-text="name"
                    item-value="value"
                    hide-no-data
                    hide-details
                    :label="$t('store.unit')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="product.cars_ids"
                    hide-no-data
                    hide-details
                    multiple
                    clearable
                    :label="$t('store.cars')"
                    :items="cars"
                    item-text="code"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model.trim="product.description"
                    clearable
                    rows="2"
                    auto-grow
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_product_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="productSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit Product -->
    <v-dialog
      v-model="edit_product_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.edit_title") }}</span>
          <v-icon class="ml-2 green--text" @click="productEditSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="edit_product_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.name"
                    :label="$t('store.name')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-combobox
                    v-model="product.group"
                    hide-no-data
                    hide-details
                    :label="$t('store.group_search')"
                    :items="products_groups"
                  >
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.sku"
                    :label="$t('store.sku')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.article"
                    :label="$t('store.article')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.store_place"
                    :label="$t('store.store_place')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="product.barcode"
                    :label="$t('store.barcode')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="product.unit"
                    :items="units"
                    item-text="name"
                    item-value="value"
                    hide-no-data
                    hide-details
                    :label="$t('store.unit')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="product.cars_ids"
                    hide-no-data
                    hide-details
                    multiple
                    clearable
                    :label="$t('store.cars')"
                    :items="cars"
                    item-text="code"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model.trim="product.description"
                    clearable
                    rows="2"
                    auto-grow
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="edit_product_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="productEditSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Store create -->
    <v-dialog
      v-model="new_store_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.store_create_title") }}</span>
          <v-icon class="ml-2 green--text" @click="storeSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="new_store_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="store.name"
                    :label="$t('store.name')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_store_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="storeSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Store edit -->
    <v-dialog
      v-model="locations_edit_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.stores") }}</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            small
            color="success"
            @click="new_store_dialog = true"
          >
            {{ $t("custom.add") }}
            <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="locations_edit_dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex
                  xs12
                  sm12
                  md12
                  class="d-inline-flex align-center"
                  v-for="location in locations"
                  :key="location.id"
                >
                  <v-text-field
                    v-model.trim="location.name"
                    :label="$t('store.name')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                  <v-icon
                    class="mx-2 mt-3 green--text"
                    @click="locationEditSave(location)"
                    style="cursor: pointer"
                  >
                    mdi-content-save-check-outline
                  </v-icon>
                  <v-icon
                    class="mr-1 mt-3"
                    color="error"
                    style="cursor: pointer"
                    @click="deleteLocation(location)"
                  >
                    mdi-delete-outline
                  </v-icon>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="locations_edit_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- приход -->
    <v-dialog
      v-model="new_arrival_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.arrival") }}</span>
          <v-icon class="ml-2 green--text" @click="arrivalSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="new_arrival_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.number="arrival.quantity"
                    :label="$t('store.quantity')"
                    type="number"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.number="arrival.price"
                    :label="$t('store.price')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="arrival.location_id"
                    hide-no-data
                    hide-details
                    :label="$t('store.store')"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="arrival.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_arrival_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="arrivalSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- расход -->
    <v-dialog
      v-model="new_departure_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.departure") }}</span>
          <v-icon class="ml-2 green--text" @click="departureSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="new_departure_dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.number="departure.quantity"
                    :label="$t('store.quantity')"
                    type="number"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="departure.location_id"
                    hide-no-data
                    hide-details
                    :label="$t('store.store')"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="departure.car_id"
                    hide-no-data
                    hide-details
                    :label="$t('store.car')"
                    :items="cars"
                    item-text="code"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="departure.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-switch
                    v-model="show_departure_price"
                    :label="$t('store.sale')"
                    dense
                    hide-details
                  ></v-switch>
                  <v-text-field
                    v-if="show_departure_price"
                    v-model.number="departure.price"
                    :label="$t('store.price')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_departure_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="departureSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- перемещение -->
    <v-dialog
      v-model="movement_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.movement") }}</span>
          <v-icon class="ml-2 green--text" @click="movementSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="movement_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="movement.location_id"
                    hide-no-data
                    hide-details
                    :label="$t('store.to')"
                    :items="filteredLocations(movement.location_from_id)"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.number="movement.quantity"
                    type="number"
                    :label="$t('store.quantity')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model.trim="movement.description"
                    clearable
                    rows="2"
                    auto-grow
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="movement_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="movementSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Kit create -->
    <v-dialog
      v-model="new_kit_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.kit_create_title") }}</span>
          <v-icon class="ml-2 green--text" @click="kitSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="new_kit_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.name"
                    :label="$t('store.kit_name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-combobox
                    v-model="kit.group"
                    hide-no-data
                    hide-details
                    :label="$t('store.group_search')"
                    :items="kits_groups"
                  >
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="kits_products"
                    hide-no-data
                    multiple
                    hide-details
                    :label="$t('store.names')"
                    :items="products"
                    item-text="name"
                    return-object
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md12
                  v-for="kit_product in kits_products"
                  :key="kit_product.id"
                >
                  <v-flex>
                    {{ kit_product.name }}
                    <v-text-field
                      v-model.number="
                        kit_product.products_quantity[`${kit_product.id}`]
                      "
                      :label="$t('store.quantity')"
                      clearable
                    ></v-text-field>
                  </v-flex>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.store_place"
                    :label="$t('store.store_place')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="kit.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_kit_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="kitSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Kit edit -->
    <v-dialog
      v-model="edit_kit_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.kit_edit_title") }}</span>
          <v-icon class="ml-2 green--text" @click="kitEditSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="edit_kit_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.name"
                    :label="$t('store.kit_name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.article"
                    :label="$t('store.article')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.barcode"
                    :label="$t('store.barcode')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-combobox
                    v-model="kit.group"
                    hide-no-data
                    hide-details
                    :label="$t('store.group_search')"
                    :items="kits_groups"
                  >
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.trim="kit.store_place"
                    :label="$t('store.store_place')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="kit.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="edit_kit_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="kitEditSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- расход комплекта -->
    <v-dialog
      v-model="new_departure_kit_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.departure_kit") }}</span>
          <v-icon class="ml-2 green--text" @click="departureKitSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="new_departure_kit_dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model.number="departure_kit.kits_quantity"
                    :label="$t('store.quantity_kits')"
                    type="number"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="departure_kit.location_id"
                    hide-no-data
                    hide-details
                    :label="$t('store.store')"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="departure_kit.car_id"
                    clearable
                    hide-no-data
                    hide-details
                    :label="$t('store.car')"
                    :items="cars"
                    item-text="code"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="departure_kit.departure_description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('store.description')"
                  ></v-textarea>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-switch
                    v-model="show_departure_price"
                    :label="$t('store.sale')"
                    dense
                    hide-details
                  ></v-switch>
                  <v-text-field
                    v-if="show_departure_price"
                    v-model.number="departure.price"
                    :label="$t('store.price')"
                    hide-no-data
                    hide-details
                  ></v-text-field>
                </v-flex> -->
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="new_departure_kit_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="departureKitSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- scanner -->
    <v-dialog
      v-model="scanner_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("store.scan_barcode") }}</span>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="scanner_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <StreamBarcodeReader
                  @decode="(a, b, c) => onDecode(a, b, c)"
                  @loaded="() => onLoaded()"
                ></StreamBarcodeReader>
                <v-flex xs12 sm12 md12>
                  <p>{{ $t("store.scan_barcode_text") }}</p>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="scanner_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import VueBarcode from "vue-barcode";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { Buffer } from "buffer";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      products: [],
      expanded_products: [],
      locations: [],
      locations_edit_dialog: false,
      kits: [],
      kit: { products_quantity: [] },
      kits_products: [],
      expanded_kits: [],
      new_kit_dialog: false,
      edit_kit_dialog: false,
      departure_kit: {},
      cars: [],
      product: { unit: 0, cars_ids: [] },
      products_headers: [
        {
          text: this.$t("custom.id"),
          align: "center",
          value: "id",
        },
        {
          text: this.$t("custom.created_at"),
          align: "center",
          value: "created_at",
        },
        {
          text: this.$t("store.name"),
          align: "center",
          value: "name",
        },
        {
          text: this.$t("store.group"),
          align: "center",
          value: "group",
        },
        {
          text: this.$t("store.stock_balance"),
          align: "center",
          value: "stock_balance",
        },
        {
          text: this.$t("store.average_price"),
          align: "center",
          value: "average_price",
        },
        {
          text: this.$t("store.sku"),
          align: "center",
          value: "sku",
        },
        {
          text: this.$t("store.article"),
          align: "center",
          value: "article",
        },
        {
          text: this.$t("store.store_place"),
          align: "center",
          value: "store_place",
        },
        {
          text: this.$t("store.barcode"),
          align: "center",
          value: "barcode",
        },
        {
          text: this.$t("store.description"),
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("custom.control"),
          align: "center",
          sortable: false,
        },
      ],
      kits_headers: [
        {
          text: this.$t("custom.id"),
          align: "center",
          value: "id",
        },
        {
          text: this.$t("custom.created_at"),
          align: "center",
          value: "created_at",
        },
        {
          text: this.$t("store.name"),
          align: "center",
          value: "name",
        },
        {
          text: this.$t("store.group"),
          align: "center",
          value: "group",
        },
        {
          text: this.$t("store.stock_balance"),
          align: "center",
          value: "stock_balance",
        },
        {
          text: this.$t("store.article"),
          align: "center",
          value: "article",
        },
        {
          text: this.$t("store.store_place"),
          align: "center",
          value: "store_place",
        },
        {
          text: this.$t("store.barcode"),
          align: "center",
          value: "barcode",
        },
        {
          text: this.$t("store.description"),
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("custom.control"),
          align: "center",
          sortable: false,
        },
      ],
      new_departure_kit_dialog: false,
      store: {},
      new_store_dialog: false,
      movement: {
        store_product_id: null,
        quantity: 1,
        location_id: null,
        description: "",
      },
      movement_dialog: false,
      arrival: { price: 0, quantity: 1, location_id: null },
      new_arrival_dialog: false,
      departure: { price: 0, quantity: 1, location_id: null },
      new_departure_dialog: false,
      units: [
        {
          value: 0,
          name: this.$t("store.pieces"),
        },
        {
          value: 1,
          name: this.$t("store.liters"),
        },
        {
          value: 2,
          name: this.$t("store.milliliters"),
        },
        {
          value: 3,
          name: this.$t("store.milligrams"),
        },
        {
          value: 4,
          name: this.$t("store.kilograms"),
        },
        {
          value: 5,
          name: this.$t("store.grams"),
        },
        {
          value: 6,
          name: this.$t("store.kits"),
        },
        {
          value: 7,
          name: this.$t("store.meters"),
        },
        {
          value: 8,
          name: this.$t("store.centimeters"),
        },
        {
          value: 9,
          name: this.$t("store.packages"),
        },
      ],
      filter_group: "",
      filter_cars: [],
      scanner_dialog: false,
      camera_loaded: false,
      show_only_in_stock: false,
      show_departure_price: false,
      workers_names: [],
      new_product_dialog: false,
      edit_product_dialog: false,
      search_products: "",
      search_kits: "",
      isLoading: false,
      isMobile: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/store_products`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.products = response.data.products;
          // For kits products quantity
          this.products.forEach((product) => {
            product.products_quantity = {};
          });
          this.locations = response.data.locations;
          this.workers_names = response.data.workers_names;

          this.axios
            .get(`/api/v1/store_kits`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.kits = response.data;
              this.getCars();
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    products_groups() {
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let groups = [];
      this.products.forEach((product) => {
        groups.push(product.group);
      });
      var unique = groups.filter(onlyUnique);
      return unique;
    },
    kits_groups() {
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let groups = [];
      this.products.forEach((product) => {
        groups.push(product.group);
      });
      this.kits.forEach((kit) => {
        groups.push(kit.group);
      });
      var unique = groups.filter(onlyUnique);
      return unique;
    },
    show_kits() {
      if (this.$store.getters.getStoreKits) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    productSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.product.name && this.product.group) {
          let self = this;
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/store_products`,
              {
                store_products: {
                  name: this.product.name,
                  group: this.product.group,
                  sku: this.product.sku,
                  unit: this.product.unit,
                  store_place: this.product.store_place,
                  cars_ids: this.product.cars_ids,
                  description: this.product.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let product = response.data;
              product.products_quantity = {};
              this.products.unshift(response.data);
              this.new_product_dialog = false;
              this.product = {};
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_product_save"));
              console.log(error.response.data.exception);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    storeSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.isLoading = true;
        this.axios
          .post(
            `/api/v1/store_locations`,
            {
              store_locations: {
                name: this.store.name,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.locations.unshift(response.data);
            this.new_store_dialog = false;
            this.store = {};
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.store_save"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    productEditSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/store_products/${this.product.id}`,
            {
              store_products: {
                name: this.product.name,
                group: this.product.group,
                sku: this.product.sku,
                article: this.product.article,
                barcode: this.product.barcode,
                store_place: this.product.store_place,
                cars_ids: this.product.cars_ids,
                description: this.product.description,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.products.splice(
              this.products.findIndex((p) => p.id == response.data.id),
              1,
              response.data
            );
            this.edit_product_dialog = false;
            this.product = {};
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.store_product_save"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editProduct(product) {
      this.product = product;
      this.edit_product_dialog = true;
    },
    deleteProduct(product) {
      if (this.checkAdminManager()) {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("store.delete_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/store_products/${product.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.products.splice(this.products.indexOf(product), 1);
                this.$swal({
                  title: this.$t("custom.deleted"),
                  icon: "success",
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_report"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    arrivalSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.arrival.location_id && this.arrival.quantity > 0) {
          let self = this;
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/store_arrivals`,
              {
                store_arrivals: {
                  price: this.arrival.price,
                  store_product_id: this.arrival.id,
                  store_location_id: this.arrival.location_id,
                  quantity: this.arrival.quantity,
                  description: this.arrival.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let arrival = response.data;
              arrival.type = "arrival";
              arrival.id = arrival.store_product_id;
              this.getProductFullInfo(arrival);
              this.new_arrival_dialog = false;
              this.arrival = {};
              this.product = {};
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.error_arrival_title"),
            text: this.$t("store.error_arrival_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    movementSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.movement.store_product_id &&
          this.movement.location_id &&
          this.movement.location_from_id &&
          this.movement.quantity > 0
        ) {
          let self = this;
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/store_arrivals`,
              {
                store_arrivals: {
                  store_product_id: this.movement.store_product_id,
                  store_location_id: this.movement.location_id,
                  quantity: this.movement.quantity,
                  description: this.movement.description
                    ? this.movement.description
                    : this.$t("store.movement"),
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let arrival = response.data;
              arrival.type = "arrival";
              arrival.id = arrival.store_product_id;
              this.getProductFullInfo(arrival);
              this.new_arrival_dialog = false;
              this.arrival = {};
            })
            .then(() => {
              this.axios
                .post(
                  `/api/v1/store_departures`,
                  {
                    store_departures: {
                      store_product_id: self.movement.store_product_id,
                      store_location_id: self.movement.location_from_id,
                      quantity: self.movement.quantity,
                      description: self.$t("store.movement"),
                    },
                  },
                  {
                    headers: {
                      Authorization: self.$store.getters.getAuthToken,
                    },
                  }
                )
                .then((response) => {
                  let departure = response.data;
                  departure.type = "departure";
                  departure.id = departure.store_product_id;
                  self.getProductFullInfo(departure);
                })
                .catch((error) => {
                  self.setError(error, self.$t("errors.store_save"));
                  console.log(error);
                });

              this.movement_dialog = false;
              this.movement = {};
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (self.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.error_arrival_title"),
            text: this.$t("store.error_arrival_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    departureSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.departure.stock_balance > 0) {
          if (this.departure.location_id && this.departure.quantity > 0) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/store_departures`,
                {
                  store_departures: {
                    price: this.departure.price,
                    store_product_id: this.departure.id,
                    store_location_id: this.departure.location_id,
                    car_id: this.departure.car_id,
                    quantity: this.departure.quantity,
                    description: this.departure.description,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let departure = response.data;
                departure.type = "departure";
                departure.id = departure.store_product_id;
                this.getProductFullInfo(departure);
                this.new_departure_dialog = false;
                this.departure = {};
                this.product = {};
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.store_save"));
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("store.error_arrival_title"),
              text: this.$t("store.error_arrival_text"),
            });
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.not_enough_quantity_title"),
            text: this.$t("store.not_enough_quantity_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    locationEditSave(location) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (location && location.name != "") {
          let self = this;
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/store_locations/${location.id}`,
              {
                store_locations: {
                  name: location.name,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.locations.splice(
                this.locations.findIndex((l) => l.id == response.data.id),
                1,
                response.data
              );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.updated"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.error_arrival_title"),
            text: this.$t("store.error_arrival_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteLocation(location) {
      if (this.checkAdminManager()) {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("store.delete_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/store_locations/${location.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.locations.splice(this.locations.indexOf(location), 1);
                this.$swal({
                  title: this.$t("custom.deleted"),
                  icon: "success",
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_report"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getProductFullInfo(product) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/store_products/${product.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.product = response.data;
          this.expanded_products.push(this.product);

          this.product.stocks = [];
          this.product.store_arrivals.forEach((arrival) => {
            this.product.stocks.push({
              id: arrival.id,
              type: "arrival",
              price: arrival.price,
              name: arrival.name,
              description: arrival.description,
              quantity: arrival.quantity,
              location_id: arrival.store_location_id,
              created_at: arrival.created_at,
              user_id: arrival.user_id,
            });
          });
          this.product.store_departures.forEach((departure) => {
            this.product.stocks.push({
              id: departure.id,
              type: "departure",
              price: departure.price,
              name: departure.name,
              description: departure.description,
              quantity: departure.quantity,
              location_id: departure.store_location_id,
              created_at: departure.created_at,
              user_id: departure.user_id,
            });
          });
          this.product.stocks.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          this.products.splice(
            this.products.findIndex((p) => p.id == this.product.id),
            1,
            this.product
          );
          this.product = {};
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    quantityInLocation(item, location) {
      let quantity = 0;
      item.store_arrivals.forEach((arrival) => {
        if (arrival.store_location_id == location.id) {
          quantity += arrival.quantity;
        }
      });
      item.store_departures.forEach((departure) => {
        if (departure.store_location_id == location.id) {
          quantity -= departure.quantity;
        }
      });
      return quantity;
    },
    getKits() {
      if (this.kits.length > 0) {
        return;
      } else {
        this.axios
          .get("/api/v1/store_kits.json", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.kits = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    kitSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.kit.name &&
          this.kit.group &&
          this.kits_products &&
          this.kits_products.length > 0
        ) {
          let products_quantity = [];
          this.kits_products.forEach((product) => {
            products_quantity.push({
              product_id: product.id,
              quantity: product.products_quantity[product.id],
            });
          });
          // console.log(products_quantity);
          // let self = this;
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/store_kits.json`,
              {
                store_kits: {
                  name: this.kit.name,
                  group: this.kit.group,
                  article: this.kit.article,
                  barcode: this.kit.barcode,
                  description: this.kit.description,
                  products_quantity: products_quantity,
                  store_place: this.kit.store_place,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let kit = response.data;
              this.kits.push(kit);
              this.new_kit_dialog = false;
              this.kit = { products_quantity: [] };
              this.kits_products = [];
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.data_not_full"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editKit(kit) {
      this.kit = kit;
      this.edit_kit_dialog = true;
    },
    kitEditSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        // function checkQuantity(products_quantity) {
        //   let result = true;
        //   products_quantity.forEach((product) => {
        //     if (product.quantity <= 0) {
        //       result = false;
        //     }
        //   });
        //   return result;
        // }
        if (
          this.kit.name &&
          this.kit.group &&
          this.kit.article &&
          this.kit.barcode
        ) {
          // console.log(products_quantity);
          // let self = this;
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/store_kits/${this.kit.id}`,
              {
                store_kits: {
                  name: this.kit.name,
                  group: this.kit.group,
                  article: this.kit.article,
                  barcode: this.kit.barcode,
                  description: this.kit.description,
                  store_place: this.kit.store_place,
                  // products_quantity: this.kit.products_quantity,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let kit = response.data;
              this.kits.splice(this.kits.indexOf(this.kit), 1, kit);
              this.edit_kit_dialog = false;
              this.kit = { products_quantity: [] };
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.data_not_full"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    departureKitSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        // Проверка на наличие на складе
        if (
          this.departure_kit.kits_quantity &&
          this.departure_kit.location_id
        ) {
          this.departure_kit.products_quantity.forEach((product) => {
            let stock_quantity = this.products.find(
              (item) => item.id == product.product_id
            ).stock_balance;
            if (
              product.quantity * this.departure_kit.kits_quantity >
              stock_quantity
            ) {
              this.$swal({
                showConfirmButton: true,
                icon: "warning",
                title: this.$t("store.not_enough_quantity_title"),
                title: this.$t("store.not_enough_quantity_text"),
              });
              return;
            }
          });

          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/departure_store_kits.json`,
              {
                departure_store_kits: {
                  kits_quantity: this.departure_kit.kits_quantity,
                  location_id: this.departure_kit.location_id,
                  car_id: this.departure_kit.car_id,
                  products_quantity: this.departure_kit.products_quantity,
                  description: this.departure_kit.departure_description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              // пересчитываю остатки комплекта
              this.departure_kit.products_quantity.forEach((product) => {
                let stock_quantity = this.products.find(
                  (item) => item.id == product.product_id
                ).stock_balance;
                this.products.find(
                  (item) => item.id == product.product_id
                ).stock_balance =
                  stock_quantity -
                  product.quantity * this.departure_kit.kits_quantity;
              });
              // Получаю созданные списания и добавляю их в массив в продукте
              this.departure_kit = {
                products_quantity: [],
              };
              this.new_departure_kit_dialog = false;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.store_save"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("store.data_not_full"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteKit(kit) {
      if (this.checkAdminManager()) {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("store.delete_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/store_kits/${kit.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.kits.splice(this.kits.indexOf(kit), 1);
                this.$swal({
                  title: this.$t("custom.deleted"),
                  icon: "success",
                });
              })
              .catch((error) => this.setError(error, this.$t("errors.error")))
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getCars() {
      if (this.cars.length > 0) {
        return;
      } else {
        this.axios
          .get("/api/v1/cars.json", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            response.data.data.forEach((element) => {
              this.cars.push(element.attributes);
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.cars_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    filter_products_groups() {
      let filtered_products = this.products;
      // this.products.forEach((product) => {
      //   filtered_products.push(product);
      // });
      if (this.filter_group && !this.show_only_in_stock) {
        filtered_products = this.products.filter((product) => {
          return product.group
            .toLowerCase()
            .includes(this.filter_group.toLowerCase());
        });
      } else if (!this.filter_group && this.show_only_in_stock) {
        filtered_products = this.products.filter((product) => {
          return product.stock_balance > 0;
        });
      } else if (this.filter_group && this.show_only_in_stock) {
        filtered_products = this.products
          .filter((product) => {
            return product.stock_balance > 0;
          })
          .filter((product) => {
            return product.group
              .toLowerCase()
              .includes(this.filter_group.toLowerCase());
          });
      }
      if (this.filter_cars && this.filter_cars.length > 0) {
        return filtered_products.filter((product) => {
          return product.cars_ids.find((car_id) => {
            return this.filter_cars.includes(car_id);
          });
        });
      }
      return filtered_products;
    },
    filter_kits_groups() {
      if (this.filter_group && !this.show_only_in_stock) {
        return this.kits.filter((product) => {
          return product.group
            .toLowerCase()
            .includes(this.filter_group.toLowerCase());
        });
      } else if (!this.filter_group && this.show_only_in_stock) {
        return this.kits.filter((product) => {
          return this.calculateKitsStock(product) > 0;
        });
      } else if (this.filter_group && this.show_only_in_stock) {
        return this.kits
          .filter((product) => {
            return this.calculateKitsStock(product) > 0;
          })
          .filter((product) => {
            return product.group
              .toLowerCase()
              .includes(this.filter_group.toLowerCase());
          });
      } else {
        return this.kits;
      }
    },
    filteredLocations(location_id) {
      return this.locations.filter((location) => {
        return location.id != location_id;
      });
    },
    onDecode(a, b, c) {
      this.search_products = a;
      this.search_kits = a;
      this.scanner_dialog = false;
      // if (this.id) clearTimeout(this.id);
      // this.id = setTimeout(() => {
      //   if (this.text === a) {
      //     this.text = "";
      //   }
      // }, 5000);
    },
    onLoaded() {
      this.camera_loaded = true;
    },
    stocks_in_location(item, location) {
      let stocks = [];
      item.stocks.forEach((stock) => {
        if (stock.location_id == location.id) {
          stocks.push(stock);
        }
      });
      return stocks;
    },
    calculateKitsStock(kit) {
      let stock = 0;
      let stock_values = [];
      let products = [];
      kit.products_quantity.forEach((product) => {
        products.push({
          id: product.product_id,
          quantity: product.quantity,
        });
      });
      products.forEach((product) => {
        let product_stock = this.products.find((p) => {
          return p.id == product.id;
        })
          ? this.products.find((p) => {
              return p.id == product.id;
            }).stock_balance
          : 0;
        if (product_stock > 0) {
          let product_quantity = kit.products_quantity.find((p) => {
            return p.product_id == product.id;
          }).quantity;
          let kit_stock = Math.floor(product_stock / product_quantity);
          stock_values.push(kit_stock);
          if (stock_values.includes(0)) {
            stock = 0;
          } else {
            stock = Math.min(...stock_values);
          }
        } else {
          stock_values.push(0);
          stock = 0;
        }
      });
      return stock;
    },
    localeUnits(value) {
      return this.units.find((unit) => unit.value == value).name;
    },
    notFindInExpendedProducts(product) {
      return this.expanded_products.findIndex((p) => p.id == product.id) == -1;
    },
    removeFromExtendedProducts(product) {
      this.expanded_products.splice(
        this.expanded_products.findIndex((p) => p.id == product.id),
        1
      );
    },
    notFindInExpendedKits(kit) {
      return this.expanded_kits.findIndex((p) => p.id == kit.id) == -1;
    },
    removeFromExtendedKits(kit) {
      this.expanded_kits.splice(
        this.expanded_kits.findIndex((p) => p.id == kit.id),
        1
      );
    },
    saveBarcode(barcode) {
      if (barcode) {
        let svg = document.getElementById(barcode).querySelector("svg");
        var xml = new XMLSerializer().serializeToString(svg);
        // var svg64 = btoa(xml);
        var svg64 = Buffer.from(xml, "utf8").toString("base64");
        var b64 = "data:image/svg+xml;base64," + svg64;
        var img = document.createElement("IMG");
        img.src = b64;

        var canvas = document.createElement("CANVAS");
        canvas.getContext("2d").drawImage(img, 0, 0);
        var dt = canvas.toDataURL("image/png");
        var a = document.createElement("a"); //Create <a>
        a.href = dt;
        a.download = `barcode_${barcode}.png`;
        a.click(); //Downloaded file
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.error"),
        });
      }
    },
    findProductName(product_id) {
      let product = this.products.find((product) => product.id == product_id);
      if (product) {
        return product.name;
      } else {
        return "?";
      }
    },
    findWorker(id) {
      let name;
      if (this.workers_names.find((worker) => worker.id == id)) {
        name = this.workers_names.find((worker) => worker.id == id).name
          ? this.workers_names.find((worker) => worker.id == id).name
          : this.workers_names.find((worker) => worker.id == id).email;
      }
      if (name) {
        return name;
      } else {
        return id;
      }
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    returnDate(item) {
      return moment(item, "YYYY-MM-DD").format("ll");
    },
    returnDateTime(item) {
      return moment(item).format("lll");
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    barcode: VueBarcode,
    StreamBarcodeReader,
  },
};
</script>
