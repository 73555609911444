<template>
  <v-container v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <!-- Удалить демо контент -->
    <v-card>
      <v-banner single-line v-if="company && showRemoveDemo">
        <v-icon slot="icon" color="warning" size="36">
          mdi-information-outline
        </v-icon>
        {{ $t("demo_content.remove_demo_content_text_2") }}
        <template v-slot:actions>
          <v-btn color="warning" @click="removeDemoContent()">
            {{ $t("demo_content.remove") }}
          </v-btn>
        </template>
      </v-banner>
      <!-- <v-card-title>
        <h2>{{ $t("companies.profile") }}</h2>
        <v-icon class="ml-2 green--text" @click="saveCompany()">
          mdi-content-save-all-outline
        </v-icon>
      </v-card-title> -->
      <v-tabs v-model="tabs" :fixed-tabs="!isMobile" :centered="!isMobile" :vertical="isMobile" id="registration-step-7">
        <v-tab>
          {{ $t("companies.prices") }}
        </v-tab>
        <v-tab id="registration-step-15">
          {{ $t("companies.rent") }}
        </v-tab>
        <v-tab id="registration-step-22">
          {{ $t("companies.company") }}
        </v-tab>
        <v-tab id="registration-step-24">
          {{ $t("companies.templates") }}
        </v-tab>
        <v-tab>
          {{ $t("companies.notifications") }}
        </v-tab>
        <v-tab>
          {{ $t("companies.clients") }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabs" touchless>
        <!-- Цены -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-flex md12 class="pb-5" id="registration-step-10">
                <h2 class="mb-3">{{ $t("companies.daily_settings") }}</h2>
                <h3 class="text-left" v-tooltip="{
                                  content: $t('companies.days_count_tooltips'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }">
                  {{ $t("companies.select_periods") }}
                </h3>
              </v-flex>
              <v-flex md12 class="text-left mt-6">
                <v-flex :class="isMobile ? 'text-center' : 'd-flex mx-auto'" md12 v-for="index in counterSliders"
                  :key="index" id="registration-step-11">
                  <v-range-slider v-model="periods[index]" color="red" min="1" max="31"
                    thumb-label="always"></v-range-slider>
                  <v-btn :class="isMobile ? 'mb-8' : ''" color="info" @click="addCounterSlider(counterSliders)"
                    id="registration-step-12">
                    +
                  </v-btn>
                  <v-btn v-if="counterSliders > 1" :class="isMobile ? 'mb-8 ml-2' : 'ml-2'" color="info" @click="
                                      (counterSliders -= 1),
                                      delete periods[`${counterSliders + 1}`]
                                      " id="registration-step-12">
                    -
                  </v-btn>
                </v-flex>
                <v-flex md12 class="text-center">
                  <v-btn color="success" @click="savePeriods()">{{
                                      $t("companies.save_periods")
                                      }}</v-btn>
                </v-flex>
                <v-divider class="mt-3 mb-3"></v-divider>
              </v-flex>
              <v-flex v-if="all_seasons.length > 0 && !isMobile" md12 class="mx-auto">
                <h3 v-tooltip="{
                                  content: $t('companies.prices_seasons'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }">
                  {{ $t("companies.prices_seasons") }}:
                </h3>
                <v-flex md12 v-for="season in all_seasons" :key="season.index" class="d-flex mx-auto justyfy-center">
                  <v-flex md1>
                    <v-select v-model="season.start_date_day" :items="days" @change="showUpdateSeasons = true"
                      class="ml-1" dense></v-select>
                  </v-flex>
                  <v-flex md3>
                    <v-select v-model="season.start_date_month" :items="months" @change="showUpdateSeasons = true"
                      item-text="text" item-value="number" class="ml-3" dense></v-select>
                  </v-flex>
                  <div class="d-flex align-center justify-center mx-2">-</div>
                  <v-flex md1>
                    <v-select v-model="season.end_date_day" :items="days" @change="showUpdateSeasons = true" class="ml-1"
                      dense></v-select>
                  </v-flex>
                  <v-flex md3>
                    <v-select v-model="season.end_date_month" :items="months" @change="showUpdateSeasons = true"
                      item-text="text" item-value="number" class="ml-1" dense></v-select>
                  </v-flex>
                  <v-flex md4 class="text-left">
                    <v-icon class="ml-2 info--text" @click="(counterCreateSeason += 1), addCreateSeason()">
                      mdi-plus-box
                    </v-icon>
                    <v-icon class="ml-2 red--text" @click="deleteSeason(season)">
                      mdi-delete-forever
                    </v-icon>
                  </v-flex>
                </v-flex>
                <v-flex v-if="showUpdateSeasons" md12 class="text-center">
                  <v-btn color="success" @click="updateSeasons()">{{
                                      $t("companies.update")
                                      }}</v-btn>
                </v-flex>
              </v-flex>
              <v-flex v-if="all_seasons.length > 0 && isMobile" md12 class="mx-auto" id="registration-step-13">
                <h3 v-tooltip="{
                                  content: $t('companies.prices_seasons'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }">
                  {{ $t("companies.prices_seasons") }}:
                </h3>
                <v-flex md12 v-for="season in all_seasons" :key="season.index" class="">
                  <v-flex md12 class="d-flex justify-center align-center">
                    <v-select v-model="season.start_date_day" :items="days" @change="showUpdateSeasons = true"
                      class="ml-1" dense></v-select>
                    <v-select v-model="season.start_date_month" :items="months" @change="showUpdateSeasons = true"
                      item-text="text" item-value="number" class="ml-3" dense></v-select>
                    <v-icon class="ml-2 info--text" @click="(counterCreateSeason += 1), addCreateSeason()">
                      mdi-plus-box
                    </v-icon>
                  </v-flex>
                  <v-flex md12 class="d-flex justify-center align-center">
                    <v-select v-model="season.end_date_day" :items="days" @change="showUpdateSeasons = true" class="ml-1"
                      dense></v-select>
                    <v-select v-model="season.end_date_month" :items="months" @change="showUpdateSeasons = true"
                      item-text="text" item-value="number" class="ml-1" dense></v-select>
                    <v-icon class="ml-2 red--text" @click="deleteSeason(season)">
                      mdi-delete-forever
                    </v-icon>
                  </v-flex>
                  <v-divider class="my-1"></v-divider>
                </v-flex>
                <v-flex v-if="showUpdateSeasons" md12 class="text-center">
                  <v-btn color="success" @click="updateSeasons()">{{
                                      $t("companies.update")
                                      }}</v-btn>
                </v-flex>
              </v-flex>
              <v-flex v-if="all_seasons.length == 0" md12 class="text-left" id="registration-step-13">
                <v-flex md12 class="d-inline-flex">
                  <h3>{{ $t("companies.no_seasons") }}</h3>
                  <v-icon class="ml-2 info--text" @click="
                                      (showCreateSeason = true),
                                      create_seasons.push({
                                        start_date_day: 1,
                                        start_date_month: '01',
                                        end_date_day: 31,
                                        end_date_month: '12',
                                      })
                                      ">
                    mdi-plus-box
                  </v-icon>
                </v-flex>
                <v-flex class="text-center">
                  <v-flex md12 v-for="(season, index) in create_seasons" :key="season.index" class="d-inline-flex">
                    <v-flex md1>
                      <v-select v-model="season.start_date_day" :items="days" class="ml-1" dense></v-select>
                    </v-flex>
                    <v-flex md3>
                      <v-select v-model="season.start_date_month" :items="months" item-text="text" item-value="number"
                        class="ml-3" dense></v-select>
                    </v-flex>
                    <v-flex md1>
                      <v-select v-model="season.end_date_day" :items="days" class="ml-1" dense></v-select>
                    </v-flex>
                    <v-flex md3>
                      <v-select v-model="season.end_date_month" :items="months" item-text="text" item-value="number"
                        class="ml-1" dense></v-select>
                    </v-flex>
                    <v-flex md4>
                      <v-icon class="ml-2 info--text" @click="(counterCreateSeason += 1), addCreateSeason()">
                        mdi-plus-box
                      </v-icon>
                      <v-icon class="ml-2 red--text" @click="removeCreateSeason(index)">
                        mdi-delete-forever
                      </v-icon>
                    </v-flex>
                  </v-flex>
                  <v-flex v-if="create_seasons.length > 0" md12 class="text-center">
                    <v-btn color="success" @click="saveSeasons()">{{
                                          $t("companies.save_seasons")
                                          }}</v-btn>
                  </v-flex>
                </v-flex>
              </v-flex>
              <v-flex md12 v-if="create_seasons.length > 0" class="text-left" id="registration-step-13">
                <v-flex md12>
                  <v-divider class="mb-3"></v-divider>
                  <h3>{{ $t("companies.creating_seasons") }}:</h3>
                </v-flex>
                <v-flex md12 v-for="season in create_seasons" :key="season.index" class="d-inline-flex">
                  <v-flex md1>
                    <v-select v-model="season.start_date_day" :items="days" class="ml-1" dense></v-select>
                  </v-flex>
                  <v-flex md3>
                    <v-select v-model="season.start_date_month" :items="months" item-text="text" item-value="number"
                      class="ml-3" dense></v-select>
                  </v-flex>
                  <v-flex md1>
                    <v-select v-model="season.end_date_day" :items="days" class="ml-1" dense></v-select>
                  </v-flex>
                  <v-flex md3>
                    <v-select v-model="season.end_date_month" :items="months" item-text="text" item-value="number"
                      class="ml-1" dense></v-select>
                  </v-flex>
                  <v-flex md4>
                    <v-icon class="ml-2 info--text" @click="(counterCreateSeason += 1), addCreateSeason()">
                      mdi-plus-box
                    </v-icon>
                    <v-icon class="ml-2 red--text" @click="removeCreateSeason(season)">
                      mdi-delete-forever
                    </v-icon>
                  </v-flex>
                </v-flex>
              </v-flex>
              <v-flex v-if="create_seasons.length > 0" md12 class="text-center">
                <v-btn color="success" @click="saveSeasons()">{{
                                  $t("companies.save_seasons")
                                  }}</v-btn>
              </v-flex>
              <v-flex md12 class="text-center">
                <v-btn id="registration-step-14" color="success" @click="saveCompany()">{{ $t("custom.save")
                                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn>
              </v-flex>
              <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                <v-flex md6 sm12>
                  <v-switch v-model="season_start_calc" class="mr-3" :label="$t('companies.season_calc_start')" dense
                    hide-details></v-switch>
                </v-flex>
                <v-flex md6 sm12>
                  <v-switch v-model="season_end_calc" class="" :label="$t('companies.season_calc_end')" dense
                    hide-details></v-switch>
                </v-flex>
              </v-flex>
              <v-flex md12 class="my-2">
                <v-divider></v-divider>
              </v-flex>
              <v-flex md12 class="pb-5">
                <h2 class="my-3">{{ $t("companies.hourly_settings") }}</h2>
                <h3 class="text-left">
                  {{ $t("companies.hourly_select_periods") }}
                </h3>
              </v-flex>
              <v-flex md12 class="text-left mt-6">
                <v-flex :class="isMobile ? 'text-center' : 'd-flex mx-auto'" md12 v-for="index in counterSlidersHourly"
                  :key="index">
                  <v-range-slider v-model="periodsHourly[index]" color="info" min="1" max="24"
                    thumb-label="always"></v-range-slider>
                  <v-btn :class="isMobile ? 'mb-8' : ''" color="info"
                    @click="addCounterSliderHourly(counterSlidersHourly)">
                    +
                  </v-btn>
                  <v-btn v-if="counterSlidersHourly > 1" :class="isMobile ? 'mb-8 ml-2' : 'ml-2'" color="info" @click="
                                      (counterSlidersHourly -= 1),
                                      delete periods[`${counterSlidersHourly + 1}`]
                                      ">
                    -
                  </v-btn>
                </v-flex>
                <v-flex md12 class="text-center">
                  <v-btn color="success" @click="savePeriodsHourly()">{{
                                      $t("companies.save_periods")
                                      }}</v-btn>
                </v-flex>
              </v-flex>
              <v-flex md12 class="my-2">
                <v-divider></v-divider>
              </v-flex>
              <v-flex md12 class="pb-5">
                <h2 class="my-3">{{ $t("companies.monthly_settings") }}</h2>
                <h3 class="text-left">
                  {{ $t("companies.monthly_select_periods") }}
                </h3>
              </v-flex>
              <v-flex md12 class="text-left mt-6">
                <v-flex :class="isMobile ? 'text-center' : 'd-flex mx-auto'" md12 v-for="index in counterSlidersMonthly"
                  :key="index">
                  <v-range-slider v-model="periodsMonthly[index]" color="secondary" min="1" max="12"
                    thumb-label="always"></v-range-slider>
                  <v-btn :class="isMobile ? 'mb-8' : ''" color="secondary"
                    @click="addCounterSliderMonthly(counterSlidersMonthly)">
                    +
                  </v-btn>
                  <v-btn v-if="counterSlidersMonthly > 1" :class="isMobile ? 'mb-8 ml-2' : 'ml-2'" color="secondary" @click="
                                      (counterSlidersMonthly -= 1),
                                      delete periods[`${counterSlidersMonthly + 1}`]
                                      ">
                    -
                  </v-btn>
                </v-flex>
                <v-flex md12 class="text-center">
                  <v-btn color="success" @click="savePeriodsMonthly()">{{
                                      $t("companies.save_periods")
                                      }}</v-btn>
                </v-flex>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Аренда -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap centered class="d-flex text-center justify-center">
                  <v-flex md3 xs12 id="registration-step-16">
                    <v-text-field v-model="company.age_limit" :label="$t('companies.age_limit')" outlined></v-text-field>
                  </v-flex>
                  <v-flex md3 xs12 id="registration-step-17">
                    <v-text-field v-model.number="company.min_days" :label="$t('companies.min_days')"
                      outlined></v-text-field>
                  </v-flex>
                  <v-flex md3 xs12 id="registration-step-18">
                    <v-text-field v-model.number="company.mileage_limit" :label="$t('companies.mileage_limit')" outlined
                      v-tooltip="{
                                              content: $t('companies.mileage_limit_info'),
                                              placement: 'bottom-center',
                                              classes: ['info'],
                                              targetClasses: ['it-has-a-tooltip'],
                                              delay: {
                                                show: 500,
                                                hide: 500,
                                              },
                                            }"></v-text-field>
                  </v-flex>
                  <v-flex md3 xs12 id="registration-step-19">
                    <v-text-field v-model.number="company.mileage_limit_cost" :label="$t('companies.mileage_limit_price')"
                      outlined></v-text-field>
                  </v-flex>
                  <v-flex md4 xs12 id="registration-step-21">
                    <v-text-field v-model.number="company.interval_bookings" :label="$t('companies.interval_bookings')"
                      v-tooltip="{
                                              content: $t('companies.interval_bookings_info'),
                                              placement: 'bottom-center',
                                              classes: ['info'],
                                              targetClasses: ['it-has-a-tooltip'],
                                              delay: {
                                                show: 500,
                                                hide: 500,
                                              },
                                            }" outlined></v-text-field>
                  </v-flex>
                  <v-flex md4 xs12 id="registration-step-21">
                    <v-text-field v-model.number="company.hours_limit" :label="$t('companies.hours_limit')" v-tooltip="{
                                          content: $t('companies.hours_limit_info'),
                                          placement: 'bottom-center',
                                          classes: ['info'],
                                          targetClasses: ['it-has-a-tooltip'],
                                          delay: {
                                            show: 500,
                                            hide: 500,
                                          },
                                        }" outlined></v-text-field>
                  </v-flex>
                  <v-flex md4 xs12>
                    <v-text-field v-model.number="company.part_return_deposit_days"
                      :label="$t('companies.part_return_deposit_days')" v-tooltip="{
                                              content: $t('companies.part_return_deposit_days_info'),
                                              placement: 'bottom-center',
                                              classes: ['info'],
                                              targetClasses: ['it-has-a-tooltip'],
                                              delay: {
                                                show: 500,
                                                hide: 500,
                                              },
                                            }" outlined></v-text-field>
                  </v-flex>
                  <v-flex md6 xs12>
                    <v-text-field v-model.trim="company.location_start" :label="$t('bookings.start_place_plc')"
                      :placeholder="$t('custom.start_location')" outlined></v-text-field>
                  </v-flex>
                  <v-flex md6 xs12>
                    <v-text-field v-model.trim="company.location_end" :label="$t('bookings.end_place_plc')"
                      :placeholder="$t('custom.start_location')" outlined></v-text-field>
                  </v-flex>
                  <v-flex md12 xs12>
                    <v-select v-model="company.rent_type" :items="booking_types"
                      :label="$t('bookings.booking_type_default')" outlined>
                    </v-select>
                  </v-flex>
                  <v-flex md3 xs12 id="registration-step-20">
                    <v-switch v-model="company.add_driver" :label="$t('companies.add_driver')" outlined v-tooltip="{
                                          content: $t('companies.add_driver_info'),
                                          placement: 'bottom-center',
                                          classes: ['info'],
                                          targetClasses: ['it-has-a-tooltip'],
                                          delay: {
                                            show: 500,
                                            hide: 500,
                                          },
                                        }"></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="registration-step-221" color="success" @click="saveCompany()">{{ $t("custom.save")
                              }}<v-icon class="ml-1">
                  mdi-content-save-check-outline
                </v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Данные компании -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap centered class="d-flex text-center justify-center">
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.name" :label="$t('companies.name')"
                      :placeholder="$t('companies.name_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.phone" :label="$t('companies.phone')"
                      :placeholder="$t('companies.phone_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.site" :label="$t('companies.site')"
                      :placeholder="$t('companies.site_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-autocomplete :label="$t('users.country')" v-model="company.country" :items="countries"
                      item-text="native" item-value="name" outlined>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.city" :label="$t('companies.city')"
                      :placeholder="$t('companies.city_plc')" outlined></v-text-field>
                  </v-flex>
                  <v-flex md5 sm12>
                    <v-text-field v-model.trim="company.address" :label="$t('companies.address_fact')"
                      :placeholder="$t('companies.address_fact_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-autocomplete v-model="company.locale" item-text="text" item-value="value" :items="locales"
                      :label="$t('companies.notification_locale')" :placeholder="$t('companies.locale_plc')"
                      outlined></v-autocomplete>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-autocomplete v-model="company.time_zone" item-text="text" item-value="value" :items="time_zones"
                      :label="$t('companies.time_zone')" :placeholder="$t('companies.time_zone_plc')"
                      outlined></v-autocomplete>
                  </v-flex>
                  <v-flex md1 sm12>
                    <v-select v-model="company.currency" :items="currencies" :label="$t('companies.currency')"
                      outlined></v-select>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-select v-model="company.company_type" :items="organizations_forms" item-text="form" item-value="id"
                      :label="$t('companies.company_type')" outlined></v-select>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.company_name" :label="$t('companies.entity_name')"
                      :placeholder="$t('companies.entity_name_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.company_adress" :label="$t('companies.entity_address')"
                      :placeholder="$t('companies.entity_address_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.company_post_adress" :label="$t('companies.postal_address')"
                      :placeholder="$t('companies.postal_address_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.inn" :label="$t('companies.tax_number')"
                      :placeholder="$t('companies.tax_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.kpp" :label="$t('companies.kpp')"
                      :placeholder="$t('companies.kpp_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.ogrn" :label="$t('companies.entity_number')"
                      :placeholder="$t('companies.entity_number_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.bank_account" :label="$t('companies.bank_account')"
                      :placeholder="$t('companies.bank_account_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.correspondent_account"
                      :label="$t('companies.correspondent_account')"
                      :placeholder="$t('companies.correspondent_account_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-text-field v-model.trim="company.bank_name" :label="$t('companies.bank_name')"
                      :placeholder="$t('companies.bank_name_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md2 sm12>
                    <v-text-field v-model.trim="company.bik" :label="$t('companies.bik')"
                      :placeholder="$t('companies.bik_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-text-field v-model.trim="company.chief_name" :label="$t('companies.chief_name')"
                      :placeholder="$t('companies.chief_name_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <!-- <v-flex md12 class="d-flex">
                    <v-file-input
                      accept="image/*"
                      :label="$t('companies.signature')"
                      v-model="sign"
                      outlined
                    ></v-file-input>
                    <v-file-input
                      accept="image/*"
                      :label="$t('companies.stamp')"
                      v-model="stamp"
                      outlined
                    ></v-file-input>
                  </v-flex> -->
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.vk" :label="$t('companies.soc_set_1')"
                      :placeholder="$t('companies.soc_set_1_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.fb" :label="$t('companies.soc_set_2')"
                      :placeholder="$t('companies.soc_set_2_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.instagram" :label="$t('companies.soc_set_3')"
                      :placeholder="$t('companies.soc_set_3_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md3 sm12>
                    <v-text-field v-model.trim="company.youtube" :label="$t('companies.soc_set_4')"
                      :placeholder="$t('companies.soc_set_4_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-text-field v-model.trim="company.yandex" :label="$t('companies.soc_set_5')"
                      :placeholder="$t('companies.soc_set_5_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-text-field v-model.trim="company.google" :label="$t('companies.soc_set_6')"
                      :placeholder="$t('companies.soc_set_6_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-text-field v-model.trim="company.gis" :label="$t('companies.soc_set_7')"
                      :placeholder="$t('companies.soc_set_7_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                                                ? 'display: none;'
                                                : ''
                                              "></v-text-field>
                  </v-flex>
                  <v-flex md12 :class="isMobile ? '' : 'd-flex align-middle justify-center'
                                      " :style="user_role == 'guest' || user_role == 'partner'
                          ? 'display: none;'
                          : ''
                        ">
                    <v-text-field v-model.trim="company.token" :label="$t('companies.token')"
                      :placeholder="$t('companies.plc_token')" readonly @click="copyToken()" ref="api_token"
                      type="password" hide-details autocomplete="off" outlined v-tooltip="{
                                              content: $t('companies.token_tooltip'),
                                              placement: 'bottom-center',
                                              classes: ['white--text', 'black'],
                                              targetClasses: ['it-has-a-tooltip'],
                                              delay: {
                                                show: 500,
                                                hide: 500,
                                              },
                                            }">
                      <v-icon slot="append" :color="company.token ? 'green' : 'grey'" @click="copyToken()"
                        cursor="pointer">
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                    <v-btn @click="updateToken()" :class="isMobile ? 'my-2' : 'mx-2 my-auto'">{{
                                          $t("companies.update_token")
                                          }}<v-icon class="ml-1" color="warning">
                        mdi-reload-alert
                      </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex md12 class="text-left" id="registration-step-23">
                    <p>{{ $t("companies.cash") }}</p>
                    <v-switch v-model="company.cash_payment" :label="$t('companies.cash_payment')" outlined></v-switch>
                  </v-flex>
                  <v-flex md12 class="text-left">
                    <p>{{ $t("companies.card") }}</p>
                    <v-switch v-model="company.card_payment" :label="$t('companies.card_payment')" outlined></v-switch>
                  </v-flex>
                  <v-flex md12 class="text-left">
                    <p>{{ $t("companies.card_to_card") }}</p>
                    <v-switch v-model="company.card_to_card_payment" :label="$t('companies.card_to_card_payment')"
                      outlined></v-switch>
                  </v-flex>
                  <v-flex md12 class="text-left">
                    <p>{{ $t("companies.entity_transfer") }}</p>
                    <v-switch v-model="company.entity_payment" :label="$t('companies.entity_payment')"
                      outlined></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="registration-step-14" color="success" @click="saveCompany()">{{ $t("custom.save")
                              }}<v-icon class="ml-1">
                  mdi-content-save-check-outline
                </v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Загрузка шаблонов -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap centered class="d-flex text-center justify-center">
                  <v-flex md12>
                    <h2 v-if="company.word_templates">
                      {{ $t("companies.template_example") }}
                    </h2>
                    <a v-if="company.word_templates" :href="$i18n.locale == 'ru'
                                            ? '/doc_example.docx'
                                            : '/doc_example_en.docx'
                                          " target="_blank" style="font-weight: bold" id="registration-step-25">{{
                                            $t("companies.template_example_download") }}</a>
                    <br v-if="company.word_templates" />
                    <a v-if="company.word_templates" :href="$i18n.locale == 'ru'
                                            ? '/entity_doc_example.docx'
                                            : '/entity_doc_example_en.docx'
                                          " target="_blank" style="font-weight: bold" id="registration-step-25">{{
                                            $t("companies.entity_template_example_download") }}</a>
                    <br v-if="company.word_templates" />
                    <a v-if="company.word_templates" :href="$i18n.locale == 'ru'
                                            ? '/invoice_example.docx'
                                            : '/invoice_example_en.docx'
                                          " target="_blank" style="font-weight: bold" id="registration-step-25">{{
                                            $t("companies.invoice_template_example_download")
                                            }}</a>
                    <br v-if="company.word_templates" />
                    <a v-if="company.word_templates" :href="$i18n.locale == 'ru'
                                            ? '/prolong_example.docx'
                                            : '/prolong_example_en.docx'
                                          " target="_blank" style="font-weight: bold" id="registration-step-25">{{
                                            $t("companies.prolong_template_example_download")
                                            }}</a>
                    <br v-if="company.word_templates" />
                    <a v-if="company.word_templates && !isIos" id="registration-step-26" text href="/help/doc_variables"
                      target="_blank" style="font-weight: bold">{{ $t("companies.variables") }}</a>
                    <v-flex class="d-flex justify-center align-center">
                      <v-switch :label="$t('templates.new_templates')" v-model="company.new_templates"
                        @change="saveCompany()" hide-details class="mr-2"></v-switch>
                      <v-switch :label="$t('templates.word_templates')" v-model="company.word_templates"
                        @change="saveCompany()" hide-details></v-switch>
                    </v-flex>
                    <v-divider class="mb-2 mt-4"></v-divider>
                  </v-flex>
                  <v-flex v-if="company.word_templates" md6 id="registration-step-27">
                    <h3 mb-3>{{ $t("companies.doc_upload") }}</h3>
                    <v-btn v-if="doc_template_url && user_role != 'guest'" small text :href="doc_template_url"
                      target="_blank">{{ $t("companies.download_current") }}</v-btn>
                    <v-flex xs12 sm12 md12 class="d-flex justify-center">
                      <uppy :max-file-size-in-bytes="10000000" width="auto" :height="100"
                        :theme="$vuetify.theme.dark ? 'dark' : 'light'" v-if="user_role != 'guest'"></uppy>
                    </v-flex>
                  </v-flex>
                  <v-flex v-if="company.word_templates" md6>
                    <h3 mb-3>{{ $t("companies.entity_upload") }}</h3>
                    <v-btn v-if="entity_doc_template_url && user_role != 'guest'" small text
                      :href="entity_doc_template_url" target="_blank">{{ $t("companies.download_current") }}</v-btn>
                    <v-flex xs12 sm12 md12 class="d-flex justify-center">
                      <uppy-entity :max-file-size-in-bytes="10000000" width="auto" :height="100"
                        :theme="$vuetify.theme.dark ? 'dark' : 'light'" v-if="user_role != 'guest'"></uppy-entity>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm12 md12 class="d-flex justify-center">
                    <v-btn v-if="!isIos" color="secondary" outlined to="/help/doc_variables">{{ $t("companies.variables")
                                          }}</v-btn>
                  </v-flex>
                  <v-flex v-if="company.word_templates" md6>
                    <h3 mb-3>{{ $t("companies.invoice_upload") }}</h3>
                    <v-btn v-if="invoice_doc_template_url && user_role != 'guest'" small text
                      :href="invoice_doc_template_url" target="_blank">{{ $t("companies.download_current") }}</v-btn>
                    <v-flex xs12 sm12 md12 class="d-flex justify-center">
                      <uppy-invoice :max-file-size-in-bytes="10000000" width="auto" :height="100"
                        :theme="$vuetify.theme.dark ? 'dark' : 'light'" v-if="user_role != 'guest'"></uppy-invoice>
                    </v-flex>
                  </v-flex>
                  <v-flex v-if="company.word_templates" md6>
                    <h3 mb-3>{{ $t("companies.prolong_upload") }}</h3>
                    <v-btn v-if="prolong_template_url && user_role != 'guest'" small text :href="prolong_template_url"
                      target="_blank">{{ $t("companies.download_current") }}</v-btn>
                    <v-flex xs12 sm12 md12 class="d-flex justify-center">
                      <uppy-prolong :max-file-size-in-bytes="10000000" width="auto" :height="100"
                        :theme="$vuetify.theme.dark ? 'dark' : 'light'" v-if="user_role != 'guest'"></uppy-prolong>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Уведомления -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container grid-list-md :class="$vuetify.theme.dark ? 'dark' : ''">
                <v-layout row wrap class="">
                  <v-flex md4 sm12>
                    <v-switch v-model="company.send_new_bookings" :label="$t('companies.notify_new_bookings')"
                      outlined></v-switch>
                  </v-flex>
                  <v-flex md8 sm12>
                    <v-text-field v-model.trim="company.email_for_bookings" :label="$t('companies.email_for_bookings')"
                      :placeholder="$t('companies.email_for_bookings_plc')" outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex md4 sm12>
                    <v-switch v-model="company.telegram_on" :label="$t('companies.telegram_on')" outlined></v-switch>
                  </v-flex>
                  <v-flex md8 sm12>
                    <v-text-field v-model.number="company.telegram_id" label="Telegram ID"
                      :placeholder="$t('companies.tg_id')" outlined hide-details></v-text-field>
                    <p>
                      <!-- {{ $t("companies.telegram_info") }} -->
                      <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
                                              }/${$root.$i18n.locale}/categories/8/guides/32`">{{ $t("telegram.more") }}</a>
                    </p>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex md12 class="text-center">
                    <h2>{{ $t("companies.whatsapp_for_clients") }}</h2>
                  </v-flex>
                  <v-flex md12 class="d-flex justify-center">
                    <v-btn v-if="!company.whatsapp" small depressed class="mx-1" color="success" :disabled="!(
                                            main_company.balance > 0
                                          )
                                          " @click.once="createWhatsappInstance()">{{ $t("companies.whatsapp_create_instance") }}
                    </v-btn>
                    <v-btn v-if="company.whatsapp" small depressed class="mx-1" color="warning" :disabled="!(
                                            company.chat_api_instance &&
                                            company.chat_api_instance.length > 0 &&
                                            company.chat_api_token &&
                                            company.chat_api_token.length > 0
                                          )
                                          " @click="deleteWhatsappInstance()">{{ $t("companies.whatsapp_delete_instance") }}
                    </v-btn>
                    <v-btn small depressed class="mx-1" color="info" :disabled="!company.whatsapp"
                      @click="authoriseWhatsapp()">
                      {{ $t("companies.whatsapp_authorise") }}
                    </v-btn>
                  </v-flex>
                  <v-flex class="md12">
                    <p>
                      {{ $t("companies.whatsapp_info") }} {{ main_company && main_company.currency == 'RUB'
                                            ? $t("companies.whatsapp_price_rub")
                                            : $t("companies.whatsapp_price_eur") }}
                    </p>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex md12>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="subheader-1">
                            {{ $t("email_templates.variables_and_tags") }}
                          </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ $t("booking_messages.html_avelable_text") }}</p> -->
                          <code>{{ "{" + "{" + "name" + "}" + "}" }}</code> -
                          {{ $t("email_templates.client_name") }},<br />
                          <code>{{ "{" + "{" + "lastname" + "}" + "}" }}</code>
                          - {{ $t("email_templates.lastname") }},<br />
                          <code>{{
                                                        "{" + "{" + "middlename" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.middlename") }},<br />
                          <code>{{
                                                        "{" + "{" + "booking_id" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.booking_id") }},<br />
                          <code>{{
                                                        "{" + "{" + "start_date" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.start_date") }},<br />
                          <code>{{ "{" + "{" + "end_date" + "}" + "}" }}</code>
                          - {{ $t("email_templates.end_date") }},<br />
                          <code>{{
                                                        "{" + "{" + "location_start" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.location_start") }},<br />
                          <code>{{
                                                        "{" + "{" + "location_end" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.location_end") }},<br />
                          <code>{{ "{" + "{" + "car_name" + "}" + "}" }}</code>
                          - {{ $t("email_templates.car_name") }},<br />
                          <code>{{ "{" + "{" + "days" + "}" + "}" }}</code> -
                          {{ $t("email_templates.days") }},<br />
                          <code>{{ "{" + "{" + "price" + "}" + "}" }}</code> -
                          {{ $t("email_templates.price") }},<br />
                          <code>{{ "{" + "{" + "total" + "}" + "}" }}</code> -
                          {{ $t("email_templates.total") }},<br />
                          <code>{{ "{" + "{" + "deposit" + "}" + "}" }}</code> -
                          {{ $t("email_templates.deposit") }},<br />
                          <code>{{
                                                        "{" + "{" + "company_name" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.company_name") }},<br />
                          <code>{{
                                                        "{" + "{" + "company_email" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.company_email") }},<br />
                          <code>{{
                                                        "{" + "{" + "company_phone" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.company_phone") }},<br />
                          <code>{{
                                                        "{" + "{" + "company_address" + "}" + "}"
                                                      }}</code>
                          - {{ $t("email_templates.company_address") }}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-switch v-model="custom_html_template" :label="$t('companies.custom_html_template')" outlined
                      @change="saveNotificationMessages()"></v-switch>
                  </v-flex>
                  <v-flex lg12 md12 v-if="custom_html_template">
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex lg12 md12 v-if="custom_html_template">
                    <v-select v-model="booking_message_selected_locale" :items="booking_message_locales"
                      :label="$t('companies.template_lang')">
                    </v-select>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex md12>
                    <h2 class="text-center mt-2">
                      {{ $t("companies.manual_email_notifications") }}
                    </h2>
                    <v-flex md12 lg12 sm12 xs12>
                      <h3>{{ $t("companies.accept_message") }}</h3>
                      <div v-if="!custom_html_template" :class="custom_html_template ? 'no-redactorx' : ''">
                        <Redactorx v-model="accept_message_text" :config="configRedactorXOptions" ref="redactorx1">
                        </Redactorx>
                      </div>
                      <div v-else>
                        <v-textarea v-model="accept_message_text" clearable auto-grow rows="1"
                          :label="$t('companies.accept_message')"></v-textarea>
                      </div>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn depressed class="mx-auto mt-2" color="success" @click="saveNotificationMessages()">{{
                                                  $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                      <h3>{{ $t("companies.accept_whatsapp") }}</h3>
                      <v-textarea v-model="accept_whatsapp" clearable auto-grow rows="1"
                        :label="$t('companies.accept_whatsapp')"></v-textarea>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn depressed class="mx-auto mt-2" color="success" @click="saveNotificationMessages()">{{
                                                  $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <v-flex md12 lg12 sm12 xs12>
                      <h3>{{ $t("companies.decline_message") }}</h3>
                      <div v-if="!custom_html_template" :class="custom_html_template ? 'no-redactorx' : ''">
                        <Redactorx v-model="decline_message_text" :config="configRedactorXOptions" ref="redactorx2">
                        </Redactorx>
                      </div>
                      <div v-else>
                        <v-textarea v-model="decline_message_text" clearable auto-grow rows="1"
                          :label="$t('companies.decline_message')"></v-textarea>
                      </div>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn depressed class="mx-auto mt-2" color="success" @click="saveNotificationMessages()">{{
                                                  $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <h3>{{ $t("companies.decline_whatsapp") }}</h3>
                    <v-textarea v-model="decline_whatsapp" clearable auto-grow rows="1"
                      :label="$t('companies.decline_whatsapp')"></v-textarea>
                    <v-flex md12 lg12 sm12 xs12 class="text-center">
                      <v-btn depressed class="mx-auto mt-2" color="success" @click="saveNotificationMessages()">{{
                                              $t("companies.save_message_text") }}
                      </v-btn>
                    </v-flex>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <h2 class="text-center">
                    {{ $t("companies.start_end_booking_message_title") }}
                  </h2>
                  <v-flex md12>
                    <v-flex md12 lg12 sm12 xs12>
                      <v-switch v-model="company.send_start_booking" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_start_message')" outlined v-tooltip="{
                                                  content: $t('companies.send_start_message_tooltip'),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_email") }}</h3>
                      <div v-if="!custom_html_template" :class="custom_html_template ? 'no-redactorx' : ''">
                        <Redactorx v-model="send_start_booking_text" :config="configRedactorXOptions" ref="redactorx3">
                        </Redactorx>
                      </div>
                      <div v-else>
                        <v-textarea v-model="send_start_booking_text" clearable auto-grow rows="1"
                          :label="$t('companies.message_text')"></v-textarea>
                      </div>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_start_booking" depressed class="mx-auto mt-2" color="success"
                          @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                      <v-switch v-model="company.send_start_booking_whatsapp" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_start_message_whatsapp')" outlined v-tooltip="{
                                                  content: $t(
                                                    'companies.send_start_message_tooltip_whatsapp'
                                                  ),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_whatsapp") }}</h3>
                      <v-textarea v-model="send_start_booking_text_whatsapp"
                        :disabled="!company.send_start_booking_whatsapp" clearable auto-grow rows="1"
                        :label="$t('companies.message_text')"></v-textarea>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_start_booking_whatsapp" depressed class="mx-auto" color="success"
                          @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <v-flex md12 lg12 sm12 xs12>
                      <v-switch v-model="company.send_end_booking" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_end_message')" outlined v-tooltip="{
                                                  content: $t('companies.send_end_message_tooltip'),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_email") }}</h3>
                      <div v-if="!custom_html_template" :class="custom_html_template ? 'no-redactorx' : ''">
                        <Redactorx v-model="send_end_booking_text" :config="configRedactorXOptions" ref="redactorx4">
                        </Redactorx>
                      </div>
                      <div v-else>
                        <v-textarea v-model="send_end_booking_text" clearable auto-grow rows="1"
                          :label="$t('companies.message_text')"></v-textarea>
                      </div>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_end_booking" depressed class="mx-auto mt-2" color="success"
                          @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                      <v-switch v-model="company.send_end_booking_whatsapp" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_end_message_whatsapp')" outlined v-tooltip="{
                                                  content: $t(
                                                    'companies.send_end_message_tooltip_whatsapp'
                                                  ),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_whatsapp") }}</h3>
                      <v-textarea v-model="send_end_booking_text_whatsapp" :disabled="!company.send_end_booking_whatsapp"
                        clearable auto-grow rows="1" :label="$t('companies.message_text')"></v-textarea>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_end_booking_whatsapp" depressed class="mx-auto" color="success"
                          @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <h2 class="text-center mt-2">
                      {{ $t("companies.review_reminder_title") }}
                    </h2>
                    <v-flex md12 lg12 sm12 xs12>
                      <v-switch v-model="company.send_review_reminder" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_review_reminder')" outlined v-tooltip="{
                                                  content: $t('companies.send_review_reminder_tooltip'),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_email") }}</h3>
                      <div v-if="!custom_html_template" :class="custom_html_template ? 'no-redactorx' : ''">
                        <Redactorx v-model="review_email_text" :config="configRedactorXOptions" ref="redactorx5">
                        </Redactorx>
                      </div>
                      <div v-else>
                        <v-textarea v-model="review_email_text" clearable auto-grow rows="1"
                          :label="$t('companies.message_text')"></v-textarea>
                      </div>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_review_reminder" depressed class="mx-auto mt-2" color="success"
                          @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                      <v-switch v-model="company.send_review_reminder_whatsapp" @change="saveMessageBookingOn()"
                        :label="$t('companies.send_review_reminder_whatsapp')" outlined v-tooltip="{
                                                  content: $t(
                                                    'companies.send_review_reminder_tooltip_whatsapp'
                                                  ),
                                                  placement: 'bottom-center',
                                                  classes: ['black white--text'],
                                                  targetClasses: ['it-has-a-tooltip'],
                                                  delay: {
                                                    show: 500,
                                                    hide: 500,
                                                  },
                                                }"></v-switch>
                      <h3>{{ $t("companies.message_text_whatsapp") }}</h3>
                      <v-textarea v-model="review_whatsapp_text" :disabled="!company.send_review_reminder_whatsapp"
                        clearable auto-grow rows="1" :label="$t('companies.message_text')"></v-textarea>
                      <v-flex md12 lg12 sm12 xs12 class="text-center">
                        <v-btn :disabled="!company.send_review_reminder_whatsapp" depressed class="mx-auto"
                          color="success" @click="saveNotificationMessages()">{{ $t("companies.save_message_text") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                  </v-flex>
                  <v-flex lg12 md12>
                    <v-divider class="my-2"></v-divider>
                  </v-flex>
                  <v-flex md12>
                    <v-switch v-model="company.new_booking_sound" :label="$t('companies.new_booking_sound')"
                      outlined></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="registration-step-14" color="success" @click="saveCompany()">{{ $t("custom.save")
                              }}<v-icon class="ml-1">
                  mdi-content-save-check-outline
                </v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Клиенты -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap centered class="d-flex text-center justify-center">
                  <v-flex md12>
                    <h2 class="mb-2">{{ $t("companies.clients_sources") }}</h2>
                    <v-btn small color="info" @click="addClientSource">
                      + {{ $t("companies.add_source") }}
                    </v-btn>
                  </v-flex>
                  <v-flex md4 xs12 v-for="(source, index) in clients_sources" :key="index"
                    class="d-flex text-center justify-center">
                    <v-text-field v-model.trim="source.value" :label="$t('companies.source')" outlined hide-details
                      dense></v-text-field>
                    <v-btn small icon @click="deleteClientSource(index)" class="mt-1 ml-1">
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveCompany()">{{ $t("custom.save")
                              }}<v-icon class="ml-1">
                  mdi-content-save-check-outline
                </v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- Удалить демо контент -->
    <v-overlay :value="showOverlayRemoveDemo">
      <v-card>
        <v-card-text>
          {{ $t("demo_content.removing_demo_content") }}
          <v-progress-linear class="mt-2" color="error accent-4" indeterminate rounded height="6"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-dialog v-model="qrCodeDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('companies.whatsapp_authorise_title') }}
        </v-card-title>

        <v-card-text class="">
          <v-img v-if="qrCode" max-height="300" max-width="300" class="mx-auto mt-2"
            :src="'data:image/png;base64,' + qrCode"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="qrCodeDialog = false">
            {{ $t('custom.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Uppy from "../../../plugins/uppy_contracts";
import UppyEntity from "../../../plugins/uppy_entity_contracts";
import UppyProlong from "../../../plugins/uppy_prolong";
import UppyInvoice from "../../../plugins/uppy_invoices";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { countries } from "./../countries";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      counts_groups: [],
      periods: {},
      periodsHourly: {},
      periodsMonthly: {},
      seasons: {
        1: {
          start_date_day: 1,
          start_date_month: "01",
          end_date_day: 1,
          end_date_month: "01",
        },
      },
      time_zones: [
        { text: "(GMT-12) International Date Line West", value: "Etc/GMT+12" },
        { text: "(GMT-11) Midway Island", value: "Pacific/Midway" },
        { text: "(GMT-11) American Samoa", value: "Pacific/Pago_Pago" },
        { text: "(GMT-10) Hawaii", value: "Pacific/Honolulu" },
        { text: "(GMT-9) Alaska", value: "America/Juneau" },
        {
          text: "(GMT-8) Pacific Time (US & Canada)",
          value: "America/Los_Angeles",
        },
        { text: "(GMT-8) Tijuana", value: "America/Tijuana" },
        {
          text: "(GMT-7) Mountain Time (US & Canada)",
          value: "America/Denver",
        },
        { text: "(GMT-7) Arizona", value: "America/Phoenix" },
        { text: "(GMT-7) Chihuahua", value: "America/Chihuahua" },
        { text: "(GMT-7) Mazatlan", value: "America/Mazatlan" },
        {
          text: "(GMT-6) Central Time (US & Canada)",
          value: "America/Chicago",
        },
        { text: "(GMT-6) Saskatchewan", value: "America/Regina" },
        { text: "(GMT-6) Guadalajara", value: "America/Mexico_City" },
        { text: "(GMT-6) Mexico City", value: "America/Mexico_City" },
        { text: "(GMT-6) Monterrey", value: "America/Monterrey" },
        { text: "(GMT-6) Central America", value: "America/Guatemala" },
        {
          text: "(GMT-5) Eastern Time (US & Canada)",
          value: "America/New_York",
        },
        {
          text: "(GMT-5) Indiana (East)",
          value: "America/Indiana/Indianapolis",
        },
        { text: "(GMT-5) Bogota", value: "America/Bogota" },
        { text: "(GMT-5) Lima", value: "America/Lima" },
        { text: "(GMT-5) Quito", value: "America/Lima" },
        { text: "(GMT-4) Atlantic Time (Canada)", value: "America/Halifax" },
        { text: "(GMT-4) Caracas", value: "America/Caracas" },
        { text: "(GMT-4) La Paz", value: "America/La_Paz" },
        { text: "(GMT-4) Newfoundland", value: "America/St_Johns" },
        { text: "(GMT-4) Georgetown", value: "America/Guyana" },
        { text: "(GMT-4) Puerto Rico", value: "America/Puerto_Rico" },
        { text: "(GMT-3) Santiago", value: "America/Santiago" },
        { text: "(GMT-3) Brasilia", value: "America/Sao_Paulo" },
        {
          text: "(GMT-3) Buenos Aires",
          value: "America/Argentina/Buenos_Aires",
        },
        { text: "(GMT-3) Montevideo", value: "America/Montevideo" },
        { text: "(GMT-3) Greenland", value: "America/Godthab" },
        { text: "(GMT-2) Mid-Atlantic", value: "Atlantic/South_Georgia" },
        { text: "(GMT-1) Azores", value: "Atlantic/Azores" },
        { text: "(GMT-1) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
        { text: "(GMT+0) Dublin", value: "Europe/Dublin" },
        { text: "(GMT+0) Edinburgh", value: "Europe/London" },
        { text: "(GMT+0) Lisbon", value: "Europe/Lisbon" },
        { text: "(GMT+0) London", value: "Europe/London" },
        { text: "(GMT+0) Monrovia", value: "Africa/Monrovia" },
        { text: "(GMT+0) UTC", value: "UTC" },
        { text: "(GMT+1) Casablanca", value: "Africa/Casablanca" },
        { text: "(GMT+1) Belgrade", value: "Europe/Belgrade" },
        { text: "(GMT+1) Bratislava", value: "Europe/Bratislava" },
        { text: "(GMT+1) Budapest", value: "Europe/Budapest" },
        { text: "(GMT+1) Ljubljana", value: "Europe/Ljubljana" },
        { text: "(GMT+1) Prague", value: "Europe/Prague" },
        { text: "(GMT+1) Sarajevo", value: "Europe/Sarajevo" },
        { text: "(GMT+1) Skopje", value: "Europe/Skopje" },
        { text: "(GMT+1) Warsaw", value: "Europe/Warsaw" },
        { text: "(GMT+1) Zagreb", value: "Europe/Zagreb" },
        { text: "(GMT+1) Brussels", value: "Europe/Brussels" },
        { text: "(GMT+1) Copenhagen", value: "Europe/Copenhagen" },
        { text: "(GMT+1) Madrid", value: "Europe/Madrid" },
        { text: "(GMT+1) Paris", value: "Europe/Paris" },
        { text: "(GMT+1) Amsterdam", value: "Europe/Amsterdam" },
        { text: "(GMT+1) Berlin", value: "Europe/Berlin" },
        { text: "(GMT+1) Bern", value: "Europe/Zurich" },
        { text: "(GMT+1) Zurich", value: "Europe/Zurich" },
        { text: "(GMT+1) Rome", value: "Europe/Rome" },
        { text: "(GMT+1) Stockholm", value: "Europe/Stockholm" },
        { text: "(GMT+1) Vienna", value: "Europe/Vienna" },
        { text: "(GMT+1) West Central Africa", value: "Africa/Algiers" },
        { text: "(GMT+2) Bucharest", value: "Europe/Bucharest" },
        { text: "(GMT+2) Cairo", value: "Africa/Cairo" },
        { text: "(GMT+2) Helsinki", value: "Europe/Helsinki" },
        { text: "(GMT+2) Kyiv", value: "Europe/Kiev" },
        { text: "(GMT+2) Riga", value: "Europe/Riga" },
        { text: "(GMT+2) Sofia", value: "Europe/Sofia" },
        { text: "(GMT+2) Tallinn", value: "Europe/Tallinn" },
        { text: "(GMT+2) Vilnius", value: "Europe/Vilnius" },
        { text: "(GMT+2) Athens", value: "Europe/Athens" },
        { text: "(GMT+2) Jerusalem", value: "Asia/Jerusalem" },
        { text: "(GMT+2) Harare", value: "Africa/Harare" },
        { text: "(GMT+2) Pretoria", value: "Africa/Johannesburg" },
        { text: "(GMT+2) Kaliningrad", value: "Europe/Kaliningrad" },
        { text: "(GMT+3) Istanbul", value: "Europe/Istanbul" },
        { text: "(GMT+3) Minsk", value: "Europe/Minsk" },
        { text: "(GMT+3) Moscow", value: "Europe/Moscow" },
        { text: "(GMT+3) St. Petersburg", value: "Europe/Moscow" },
        { text: "(GMT+3) Volgograd", value: "Europe/Volgograd" },
        { text: "(GMT+3) Kuwait", value: "Asia/Kuwait" },
        { text: "(GMT+3) Riyadh", value: "Asia/Riyadh" },
        { text: "(GMT+3) Nairobi", value: "Africa/Nairobi" },
        { text: "(GMT+3) Baghdad", value: "Asia/Baghdad" },
        { text: "(GMT+3) Tehran", value: "Asia/Tehran" },
        { text: "(GMT+4) Samara", value: "Europe/Samara" },
        { text: "(GMT+4) Abu Dhabi", value: "Asia/Muscat" },
        { text: "(GMT+4) Muscat", value: "Asia/Muscat" },
        { text: "(GMT+4) Baku", value: "Asia/Baku" },
        { text: "(GMT+4) Tbilisi", value: "Asia/Tbilisi" },
        { text: "(GMT+4) Yerevan", value: "Asia/Yerevan" },
        { text: "(GMT+4) Kabul", value: "Asia/Kabul" },
        { text: "(GMT+5) Ekaterinburg", value: "Asia/Yekaterinburg" },
        { text: "(GMT+5) Islamabad", value: "Asia/Karachi" },
        { text: "(GMT+5) Karachi", value: "Asia/Karachi" },
        { text: "(GMT+5) Tashkent", value: "Asia/Tashkent" },
        { text: "(GMT+5) Chennai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kolkata", value: "Asia/Kolkata" },
        { text: "(GMT+5) Mumbai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kathmandu", value: "Asia/Kathmandu" },
        { text: "(GMT+5) New Delhi", value: "Asia/Kolkata" },
        { text: "(GMT+5) Sri Jayawardenepura", value: "Asia/Colombo" },
        { text: "(GMT+6) Astana", value: "Asia/Dhaka" },
        { text: "(GMT+6) Dhaka", value: "Asia/Dhaka" },
        { text: "(GMT+6) Almaty", value: "Asia/Almaty" },
        { text: "(GMT+6) Rangoon", value: "Asia/Rangoon" },
        { text: "(GMT+6) Urumqi", value: "Asia/Urumqi" },
        { text: "(GMT+7) Novosibirsk", value: "Asia/Novosibirsk" },
        { text: "(GMT+7) Bangkok", value: "Asia/Bangkok" },
        { text: "(GMT+7) Hanoi", value: "Asia/Bangkok" },
        { text: "(GMT+7) Jakarta", value: "Asia/Jakarta" },
        { text: "(GMT+7) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        { text: "(GMT+8) Beijing", value: "Asia/Shanghai" },
        { text: "(GMT+8) Chongqing", value: "Asia/Chongqing" },
        { text: "(GMT+8) Hong Kong", value: "Asia/Hong_Kong" },
        { text: "(GMT+8) Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
        { text: "(GMT+8) Singapore", value: "Asia/Singapore" },
        { text: "(GMT+8) Taipei", value: "Asia/Taipei" },
        { text: "(GMT+8) Perth", value: "Australia/Perth" },
        { text: "(GMT+8) Irkutsk", value: "Asia/Irkutsk" },
        { text: "(GMT+8) Ulaanbaatar", value: "Asia/Ulaanbaatar" },
        { text: "(GMT+9) Seoul", value: "Asia/Seoul" },
        { text: "(GMT+9) Osaka", value: "Asia/Tokyo" },
        { text: "(GMT+9) Sapporo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Tokyo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Yakutsk", value: "Asia/Yakutsk" },
        { text: "(GMT+9) Darwin", value: "Australia/Darwin" },
        { text: "(GMT+10) Adelaide", value: "Australia/Adelaide" },
        { text: "(GMT+10) Brisbane", value: "Australia/Brisbane" },
        { text: "(GMT+10) Vladivostok", value: "Asia/Vladivostok" },
        { text: "(GMT+10) Guam", value: "Pacific/Guam" },
        { text: "(GMT+10) Port Moresby", value: "Pacific/Port_Moresby" },
        { text: "(GMT+11) Canberra", value: "Australia/Melbourne" },
        { text: "(GMT+11) Melbourne", value: "Australia/Melbourne" },
        { text: "(GMT+11) Sydney", value: "Australia/Sydney" },
        { text: "(GMT+11) Hobart", value: "Australia/Hobart" },
        { text: "(GMT+11) Magadan", value: "Asia/Magadan" },
        { text: "(GMT+11) Srednekolymsk", value: "Asia/Srednekolymsk" },
        { text: "(GMT+11) Solomon Is.", value: "Pacific/Guadalcanal" },
        { text: "(GMT+11) New Caledonia", value: "Pacific/Noumea" },
        { text: "(GMT+12) Fiji", value: "Pacific/Fiji" },
        { text: "(GMT+12) Kamchatka", value: "Asia/Kamchatka" },
        { text: "(GMT+12) Marshall Is.", value: "Pacific/Majuro" },
        { text: "(GMT+13) Auckland", value: "Pacific/Auckland" },
        { text: "(GMT+13) Wellington", value: "Pacific/Auckland" },
        { text: "(GMT+13) Nuku'alofa", value: "Pacific/Tongatapu" },
        { text: "(GMT+13) Tokelau Is.", value: "Pacific/Fakaofo" },
        { text: "(GMT+13) Chatham Is.", value: "Pacific/Chatham" },
        { text: "(GMT+13) Samoa", value: "Pacific/Apia" },
      ],
      locales: [
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      booking_message_locales: [
        { text: this.$t("companies.default_lang"), value: null },
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      booking_message_selected_locale: null,
      booking_types: [
        { text: this.$t("bookings.daily"), value: 0 },
        { text: this.$t("bookings.hourly"), value: 1 },
        { text: this.$t("bookings.monthly"), value: 2 },
      ],
      countries: countries,
      all_seasons: [],
      create_seasons: [],
      counterSliders: 1,
      counterSlidersHourly: 1,
      counterSlidersMonthly: 1,
      counterSeasons: 1,
      counterCreateSeason: 1,
      season_start_calc: true,
      season_end_calc: false,
      user: "",
      currencies: [
        "€",
        "$",
        "£",
        "₽",
        "Br",
        "₴",
        "₹",
        "₺",
        "₸",
        "₱",
        "₪",
        "RD$",
        "zł",
        "Kč",
        "฿",
        "AED",
        "RON",
        "RSD",
        "CHF",
        "₡",
        "₫",
        "₩",
        "¥",
        "₦",
        "₮",
        "₾",
        "F",
        "XPF",
        "L",
        "kn",
        "K",
        "MK",
        "OMR",
        "QAR",
        "SAR",
        "MAD",
        "KGS",
        "KZT",
        "c",
        "сом",
      ],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      months: [
        { text: this.$t("months.january"), number: "01", days: 31 },
        { text: this.$t("months.february"), number: "02", days: 28 },
        { text: this.$t("months.march"), number: "03", days: 31 },
        { text: this.$t("months.april"), number: "04", days: 30 },
        { text: this.$t("months.may"), number: "05", days: 31 },
        { text: this.$t("months.june"), number: "06", days: 30 },
        { text: this.$t("months.july"), number: "07", days: 31 },
        { text: this.$t("months.august"), number: "08", days: 31 },
        { text: this.$t("months.september"), number: "09", days: 30 },
        { text: this.$t("months.october"), number: "10", days: 31 },
        { text: this.$t("months.november"), number: "11", days: 30 },
        { text: this.$t("months.december"), number: "12", days: 31 },
      ],
      tariffs: [
        { text: this.$t("rentprog_tariffs.start"), value: "Старт" },
        { text: this.$t("rentprog_tariffs.start"), value: "Безлимит" },
        { text: this.$t("rentprog_tariffs.start"), value: "Стандарт" },
        { text: this.$t("rentprog_tariffs.start"), value: "Средний" },
        { text: this.$t("rentprog_tariffs.start"), value: "Профессионал" },
        { text: this.$t("rentprog_tariffs.start"), value: "Эксперт" },
        { text: this.$t("rentprog_tariffs.start"), value: "Индивидуальный" },
      ],
      clients_sources: [],
      clients_sources_array: [],
      organizations_forms: [
        { id: 0, form: this.$t("organizations_forms.other") },
        { id: 1, form: this.$t("organizations_forms.ooo") },
        { id: 2, form: this.$t("organizations_forms.ip") },
        { id: 3, form: this.$t("organizations_forms.impresario") },
      ],
      new_counts_group: "",
      edit_counts_group: "",
      old_counts_group: "",
      doc_template_url: null,
      entity_doc_template_url: null,
      prolong_template_url: null,
      invoice_doc_template_url: null,
      send_start_booking_text: null,
      send_end_booking_text: null,
      send_start_booking_text_whatsapp: null,
      send_end_booking_text_whatsapp: null,
      review_email_text: null,
      review_whatsapp_text: null,
      accept_message_text: null,
      custom_html_template: false,
      decline_message_text: null,
      accept_whatsapp: null,
      decline_whatsapp: null,
      tabs: 0,
      sign: null,
      stamp: null,
      configRedactorXOptions: {
        embed: false,
        control: true,
        context: true,
        toolbar: {
          stickyTopOffset: 65,
        },
        editor: {
          lang: this.$i18n.locale == "ru" ? "ru" : "en",
        },
        plugins: ["alignment", "imageposition", "imageresize", "removeformat"],
        image: {
          upload: process.env.NODE_ENV === "development" ? "http://localhost:3000/email_templates_image_upload" : "https://rentprog.net/email_templates_image_upload",
          multiple: false,
          data: {
            user_id: this.$store.getters.getCurrentUser.id,
          },
        },
      },
      showEdit: false,
      showSaveSeasons: false,
      showUpdateSeasons: false,
      showNewCountsGroup: false,
      showEditCountsGroup: false,
      showCreateSeason: false,
      showOverlayRemoveDemo: false,
      qrCodeDialog: false,
      qrCode: null,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager ||
        this.user_role == "guest"
      ) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/company`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.$store.commit("setCompany", { company: response.data });
            if (response.data.periods) {
              self.counterSliders = 0;
              response.data.periods.forEach((period, index) => {
                self.counterSliders += 1;
                // Чтобы редактировалос
                self.$set(self.periods, `${index + 1}`, [
                  parseInt(period.split(" - ")[0]),
                  parseInt(period.split(" - ")[1]),
                ]);
                // self.periods[`${index + 1}`] = [parseInt(period.split(" - ")[0]), parseInt(period.split(" - ")[1])]
              });
              // console.log(self.periods)
            }
            if (response.data.hourly_periods) {
              self.counterSlidersHourly = 0;
              response.data.hourly_periods.forEach((period, index) => {
                self.counterSlidersHourly += 1;
                // console.log(period, index, self.counterSlidersHourly)
                // let set_periods = {};
                // set_periods[`${index + 1}`] = [
                //   parseInt(period.split(" - ")[0]),
                //   parseInt(period.split(" - ")[1]),
                // ];
                // console.log(set_periods)
                // Чтобы редактировалос
                self.$set(self.periodsHourly, `${index + 1}`, [
                  parseInt(period.split(" - ")[0]),
                  parseInt(period.split(" - ")[1]),
                ]);
              });
            }
            if (response.data.monthly_periods) {
              self.counterSlidersMonthly = 0;
              response.data.monthly_periods.forEach((period, index) => {
                self.counterSlidersMonthly += 1;
                // Чтобы редактировалос
                self.$set(self.periodsMonthly, `${index + 1}`, [
                  parseInt(period.split(" - ")[0]),
                  parseInt(period.split(" - ")[1]),
                ]);
              });
            }
            this.season_start_calc = response.data.season_start_calc;
            this.season_end_calc = response.data.season_end_calc;
            this.axios
              .get(`/api/v1/counts_groups`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((counts_response) => {
                function onlyUnique(value, index, self) {
                  return self.indexOf(value) === index;
                }
                self.company.counts_groups =
                  counts_response.data.filter(onlyUnique);
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.counts_groups_load"));
                console.log(error);
              });

            // Формируем массив для отображения источников клиентов
            if (
              this.company.clients_sources &&
              this.company.clients_sources.length > 0
            ) {
              this.company.clients_sources.forEach((source) => {
                self.clients_sources.push({ value: source });
              });
            }

            this.user = this.$store.getters.getCurrentUser;
            this.getBookingMessages();
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.company_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/doc_templates/${null}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              self.doc_template_url = response.data.url;
            } else {
              self.doc_template_url =
                "https://web.rentprog.ru/doc_example.docx";
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.template_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/doc_entity_templates/${null}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              self.entity_doc_template_url = response.data.url;
            } else {
              self.entity_doc_template_url =
                "https://web.rentprog.ru/entity_doc_example.docx";
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.template_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/prolong_templates`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              self.prolong_template_url = response.data.url;
            } else {
              self.prolong_template_url =
                "https://web.rentprog.ru/doc_example.docx";
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.template_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/invoice_templates`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              self.invoice_doc_template_url = response.data.url;
            } else {
              self.invoice_doc_template_url =
                "https://web.rentprog.ru/invoice_example.docx";
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.template_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/seasons`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let counter = 0;
            response.data.forEach((season) => {
              let split_start_date = season.start_date.split(".");
              let split_end_date = season.end_date.split(".");
              counter += 1;
              self.all_seasons.push({
                id: season.id,
                start_date_day: parseInt(split_start_date[0]),
                start_date_month: split_start_date[1],
                end_date_day: parseInt(split_end_date[0]),
                end_date_month: split_end_date[1],
              });
            });
            if (self.all_seasons != {}) {
              self.seasons = self.all_seasons;
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.seasons_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
          text: this.$t("errors.only_admins_2"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (this.$tours["registrationTour"]) {
      this.$tours["registrationTour"].nextStep();
    }
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  watch: {
    sign(value) {
      let fr = new FileReader();
      let self = this;
      fr.addEventListener("load", function (e) {
        self.isLoading = true;
        self.axios
          .post(
            "/api/v1/company_signature",
            {
              company_signatures: {
                sign: e.target.result,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: self.$t("sign.upload_success"),
            });
          })
          .catch((error) => {
            self.isLoading = false;
            self.setError(error, self.$t("errors.sign_upload"));
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: self.$t("errors.sign_upload"),
              text: self.error,
            });
          })
          .finally(() => (self.isLoading = false));
      });
      fr.readAsDataURL(value);
    },
    stamp(value) {
      let fr = new FileReader();
      let self = this;
      fr.addEventListener("load", function (e) {
        self.isLoading = true;
        self.axios
          .post(
            "/api/v1/company_stamp",
            {
              company_stamps: {
                stamp: e.target.result,
              },
            },
            {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: self.$t("sign.upload_stamp_success"),
            });
          })
          .catch((error) => {
            self.isLoading = false;
            self.setError(error, self.$t("errors.stamp_upload"));
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: self.$t("errors.stamp_upload"),
              text: self.error,
            });
          })
          .finally(() => (self.isLoading = false));
      });
      fr.readAsDataURL(value);
    },
    season_start_calc() {
      if (this.season_end_calc == false && this.season_start_calc == false) {
        this.$swal({
          showConfirmButton: true,
          icon: "error",
          title: this.$t("errors.season_calc"),
          text: this.$t("errors.season_calc_text"),
        });
      }
    },
    season_end_calc() {
      if (this.season_end_calc == false && this.season_start_calc == false) {
        this.$swal({
          showConfirmButton: true,
          icon: "error",
          title: this.$t("errors.season_calc"),
          text: this.$t("errors.season_calc_text"),
        });
      }
    },
    booking_message_selected_locale() {
      this.getBookingMessages();
      // this.$nextTick(() => {
      //   this.custom_html_template = true;
      // });
      // this.$refs.redactorx.destroy();
      // this.$refs.redactorx.init(this.configRedactorXOptions);
      // console.log(this.$refs.redactorx)
      // RedactorX(this.$refs.redactorx, "init", this.configRedactorXOptions);
      // this.$nextTick(() => {
      //   RedactorX(this.$refs.redactorx, this.configRedactorXOptions);
      // });
    },
  },
  computed: {
    company() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company;
      }
    },
    main_company() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    showRemoveDemo() {
      return this.$store.getters.getCompany.company.demo_content;
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    saveCompany() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let company = this.company;
        let self = this;
        if (this.new_counts_group != "") {
          company.counts_groups.push(this.new_counts_group);
        }
        if (this.edit_counts_group != "") {
          let foundIndex = company.counts_groups.findIndex(
            (element) => element === this.old_counts_group
          );
          company.counts_groups.splice(foundIndex, 1, this.edit_counts_group);
          this.axios
            .get(
              `/api/v1/update_counts_group/${this.old_counts_group}&${this.edit_counts_group}`,
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .catch((error) => {
              this.setError(error, this.$t("errors.counts_groups_upload"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.counts_groups_upload"),
                text: this.error,
              });
            });
        }

        // Формируем массив источников клиентов
        if (this.clients_sources && this.clients_sources.length > 0) {
          this.clients_sources_array = [];
          this.clients_sources.forEach((source) => {
            self.clients_sources_array.push(source.value);
          });
        }

        this.axios
          .patch(
            `/api/v1/company`,
            {
              company: {
                name: company.name,
                age_limit: company.age_limit,
                hours_limit: company.hours_limit,
                min_days: company.min_days,
                rent_type: company.rent_type,
                location_start: company.location_start,
                location_end: company.location_end,
                free_cancel: company.free_cancel,
                gas_cost: company.gas_cost,
                in_booking: company.in_booking,
                email_for_bookings: company.email_for_bookings,
                send_new_bookings: company.send_new_bookings,
                telegram_id: company.telegram_id,
                telegram_on: company.telegram_on,
                whatsapp: company.whatsapp,
                chat_api_instance: company.chat_api_instance,
                chat_api_token: company.chat_api_token,
                cash_payment: company.cash_payment,
                card_payment: company.card_payment,
                card_to_card_payment: company.card_to_card_payment,
                cashless_payment: company.cashless_payment,
                entity_payment: company.entity_payment,
                interval_bookings: company.interval_bookings,
                add_driver: company.add_driver,
                add_driver_cost: company.add_driver_cost,
                mileage_limit: company.mileage_limit,
                mileage_limit_cost: company.mileage_limit_cost,
                counts_groups: company.counts_groups,
                phone: company.phone,
                country: company.country,
                time_zone: company.time_zone,
                locale: company.locale,
                city: company.city,
                address: company.address,
                currency: company.currency,
                site: company.site,
                vk: company.vk,
                fb: company.fb,
                instagram: company.instagram,
                youtube: company.youtube,
                logout_interval: company.logout_interval,
                yandex: company.yandex,
                google: company.google,
                gis: company.gis,
                autofon_token: company.autofon_token,
                autofon_pwd: company.autofon_pwd,
                starline_app_id: company.starline_app_id,
                starline_secret: company.starline_secret,
                company_type: company.company_type,
                company_name: company.company_name,
                company_adress: company.company_adress,
                company_post_adress: company.company_post_adress,
                inn: company.inn,
                kpp: company.kpp,
                ogrn: company.ogrn,
                bank_account: company.bank_account,
                correspondent_account: company.correspondent_account,
                bank_name: company.bank_name,
                bik: company.bik,
                chief_name: company.chief_name,
                part_return_deposit_days: company.part_return_deposit_days,
                clients_sources: self.clients_sources_array,
                word_templates: company.word_templates,
                new_templates: company.new_templates,
                season_start_calc: self.season_start_calc,
                season_end_calc: self.season_end_calc,
                new_booking_sound: company.new_booking_sound,
                send_start_booking: company.send_start_booking,
                send_end_booking: company.send_end_booking,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$store.commit("setCompany", { company: response.data });
            this.showEdit = false;
            this.showEditCountsGroup = false;
            this.showNewCountsGroup = false;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("companies.updated_success"),
            });

            // this.$swal({
            //   title: "Профиль компании изменен!",
            //   text:
            //     "Вы успешно изменили информацию о компании! Сейчас будет призведен выход из текущего профиля, надо войти заново чтобы информация обновилась.",
            //   icon: "warning",
            //   showCancelButton: true,
            //   confirmButtonText: "Ок, выходим!",
            //   cancelButtonText: "Нет, остаемся, надо внести другие изменения!",
            //   showCloseButton: true
            // }).then(result => {
            //   if (result.value) {
            //     this.$store.commit("unsetCurrentUser");
            //   }
            // });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.profile_save"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.profile_save"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    savePeriods() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let company = this.company;
        // проверка что каждый элемент массива больше предыдущего
        function periodsValidate(array) {
          var length = array.length;
          return array.every(function (value, index) {
            var nextIndex = index + 1;
            return nextIndex < length ? value <= array[nextIndex] : true;
          });
        }
        let periods_values = [];
        Object.values(this.periods).forEach((period) => {
          periods_values.push(period[0]);
          periods_values.push(period[1]);
        });

        if (this.periods && periodsValidate(periods_values)) {
          this.isLoading = true;
          function setPeriods(periods) {
            // { "1": [ 1, 3 ], "2": [ 4, 7 ], "3": [ 8, 15 ], "4": [ 16, 30 ] }
            let result = [];
            Object.values(periods).forEach((period) => {
              result.push(`${period[0]} - ${period[1]}`);
            });
            if (result.length > 0) {
              return result;
            } else {
              return company.periods;
            }
          }
          this.axios
            .patch(
              `/api/v1/company`,
              {
                company: {
                  periods: setPeriods(self.periods),
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$store.commit("setCompany", { company: response.data });
              this.showEdit = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("companies.periods_saved"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.periods_save"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.periods_save"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          self.$swal({
            icon: "warning",
            title: self.$t("errors.periods_format_title"),
            text: self.$t("errors.periods_format_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    savePeriodsHourly() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let company = this.company;
        // проверка что каждый элемент массива больше предыдущего
        function periodsValidate(array) {
          var length = array.length;
          return array.every(function (value, index) {
            var nextIndex = index + 1;
            return nextIndex < length ? value <= array[nextIndex] : true;
          });
        }
        let periods_values = [];
        Object.values(this.periodsHourly).forEach((period) => {
          periods_values.push(period[0]);
          periods_values.push(period[1]);
        });

        if (this.periodsHourly && periodsValidate(periods_values)) {
          this.isLoading = true;
          function setPeriods(periods) {
            // { "1": [ 1, 3 ], "2": [ 4, 7 ], "3": [ 8, 15 ], "4": [ 16, 24 ] }
            let result = [];
            Object.values(periods).forEach((period) => {
              result.push(`${period[0]} - ${period[1]}`);
            });
            if (result.length > 0) {
              return result;
            } else {
              return company.hourly_periods;
            }
          }
          this.axios
            .patch(
              `/api/v1/company`,
              {
                company: {
                  hourly_periods: setPeriods(self.periodsHourly),
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$store.commit("setCompany", { company: response.data });
              this.showEdit = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("companies.periods_saved"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.periods_save"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.periods_save"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          self.$swal({
            icon: "warning",
            title: self.$t("errors.periods_format_title"),
            text: self.$t("errors.periods_format_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    savePeriodsMonthly() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let company = this.company;
        // проверка что каждый элемент массива больше предыдущего
        function periodsValidate(array) {
          var length = array.length;
          return array.every(function (value, index) {
            var nextIndex = index + 1;
            return nextIndex < length ? value <= array[nextIndex] : true;
          });
        }
        let periods_values = [];
        Object.values(this.periodsMonthly).forEach((period) => {
          periods_values.push(period[0]);
          periods_values.push(period[1]);
        });

        if (this.periodsMonthly && periodsValidate(periods_values)) {
          this.isLoading = true;
          function setPeriods(periods) {
            // { "1": [ 1, 3 ], "2": [ 4, 7 ], "3": [ 8, 15 ], "4": [ 16, 24 ] }
            let result = [];
            Object.values(periods).forEach((period) => {
              result.push(`${period[0]} - ${period[1]}`);
            });
            if (result.length > 0) {
              return result;
            } else {
              return company.monthly_periods;
            }
          }
          this.axios
            .patch(
              `/api/v1/company`,
              {
                company: {
                  monthly_periods: setPeriods(self.periodsMonthly),
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$store.commit("setCompany", { company: response.data });
              this.showEdit = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("companies.periods_saved"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.periods_save"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.periods_save"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          self.$swal({
            icon: "warning",
            title: self.$t("errors.periods_format_title"),
            text: self.$t("errors.periods_format_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addSeason() {
      this.all_seasons.push({
        start_date_day: 1,
        start_date_month: "01",
        end_date_day: 31,
        end_date_month: "12",
      });
      // Реактивность для объекта
      // this.$set(this.seasons, this.counterSeasons, {'start_date_day': 1, 'start_date_month':'01', 'end_date_day': 31, 'end_date_month':'12'})
    },
    addCounterSlider() {
      this.counterSliders += 1;
      // this.periods[this.counterSliders] = [this.periods[this.counterSliders - 1][1] + 1, this.periods[this.counterSliders - 1][1] + 2]
      this.$set(this.periods, this.counterSliders, [
        this.periods[this.counterSliders - 1][1] + 1,
        this.periods[this.counterSliders - 1][1] + 2,
      ]);
    },
    addCounterSliderHourly() {
      this.counterSlidersHourly += 1;
      this.$set(this.periodsHourly, this.counterSlidersHourly, [
        this.periodsHourly[this.counterSlidersHourly - 1][1] + 1,
        this.periodsHourly[this.counterSlidersHourly - 1][1] + 2,
      ]);
    },
    addCounterSliderMonthly() {
      this.counterSlidersMonthly += 1;
      this.$set(this.periodsMonthly, this.counterSlidersMonthly, [
        this.periodsMonthly[this.counterSlidersMonthly - 1][1] + 1,
        this.periodsMonthly[this.counterSlidersMonthly - 1][1] + 2,
      ]);
    },
    updateSeasons() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let seasons = this.all_seasons;
        let self = this;
        if (seasons && seasons.length > 0) {
          let check = true;
          function checkSeason(seasons) {
            Object.values(seasons).forEach((season) => {
              let start = moment(
                `${season.start_date_day}.${season.start_date_month}`,
                "DD.MM"
              );
              let end = moment(
                `${season.end_date_day}.${season.end_date_month}`,
                "DD.MM"
              );
              if (start.isValid() && end.isValid()) {
                if (start.isAfter(end)) {
                  self.$swal({
                    icon: "warning",
                    title: self.$t("errors.seasons_format_title"),
                    text: self.$t("errors.seasons_format_text"),
                  });
                  check = false;
                  return false;
                } else {
                  return true;
                }
              } else {
                self.$swal({
                  icon: "warning",
                  title: self.$t("errors.seasons_format_title"),
                  text: self.$t("errors.seasons_format_text"),
                });
                check = false;
                return false;
              }
            });
          }
          function checkSeasonCross(seasons) {
            Object.values(seasons).forEach((season) => {
              let start = moment(
                `${season.start_date_day}.${season.start_date_month}`,
                "DD.MM"
              );
              let end = moment(
                `${season.end_date_day}.${season.end_date_month}`,
                "DD.MM"
              );
              self.all_seasons.forEach((all_season) => {
                let start_check = moment(
                  `${all_season.start_date_day}.${all_season.start_date_month}`,
                  "DD.MM"
                );
                let end_check = moment(
                  `${all_season.end_date_day}.${all_season.end_date_month}`,
                  "DD.MM"
                );
                if (
                  start.isBetween(start_check, end_check) ||
                  end.isBetween(start_check, end_check)
                ) {
                  self.$swal({
                    showConfirmButton: true,
                    icon: "warning",
                    title: self.$t("errors.seasons_cross"),
                  });
                  check = false;
                  return false;
                } else {
                  return true;
                }
              });
            });
          }
          console.log("check", check);
          // Проверка существующих сезонов
          checkSeason(seasons);
          // Проверка пересечения с существующими сезонами
          checkSeasonCross(seasons);
          if (check) {
            Object.values(seasons).forEach((season) => {
              if (
                season.start_date_day &&
                season.start_date_month &&
                season.end_date_day &&
                season.end_date_month
              ) {
                self.axios
                  .patch(
                    `/api/v1/seasons/${season.id}`,
                    {
                      seasons: {
                        start_date: `${season.start_date_day}.${season.start_date_month}`,
                        end_date: `${season.end_date_day}.${season.end_date_month}`,
                      },
                    },
                    {
                      headers: {
                        Authorization: this.$store.getters.getAuthToken,
                      },
                    }
                  )
                  .then((response) => {
                    this.showUpdateSeasons = false;
                    this.$swal(
                      this.$t("companies.seasons_updated"),
                      this.$t("companies.seasons_updated_text"),
                      "success"
                    );
                  })
                  .catch((error) => {
                    self.setError(error, self.$t("errors.seasons_update"));
                    self.$swal({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      icon: "error",
                      title: self.$t("errors.seasons_update"),
                      text: self.error,
                    });
                  });
              } else {
                self.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "warning",
                  title: self.$t("errors.seasons_no_data"),
                  text: self.error,
                });
              }
            });
          }
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.seasons_updated"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addCreateSeason() {
      this.create_seasons.push({
        start_date_day: 1,
        start_date_month: "01",
        end_date_day: 31,
        end_date_month: "12",
      });
    },
    saveSeasons() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let seasons = this.create_seasons;
        let self = this;
        if (
          seasons &&
          seasons !=
          {
            1: {
              start_date_day: 1,
              start_date_month: "01",
              end_date_day: 1,
              end_date_month: "01",
            },
          }
        ) {
          let check = true;
          function checkSeason(seasons) {
            Object.values(seasons).forEach((season) => {
              let start = moment(
                `${season.start_date_day}.${season.start_date_month}`,
                "DD.MM"
              );
              let end = moment(
                `${season.end_date_day}.${season.end_date_month}`,
                "DD.MM"
              );
              if (start.isValid() && end.isValid()) {
                if (start.isAfter(end)) {
                  self.$swal({
                    icon: "warning",
                    title: self.$t("errors.seasons_format_title"),
                    text: self.$t("errors.seasons_format_text"),
                  });
                  check = false;
                  return false;
                } else {
                  return true;
                }
              } else {
                self.$swal({
                  icon: "warning",
                  title: self.$t("errors.seasons_format_title"),
                  text: self.$t("errors.seasons_format_text"),
                });
                check = false;
                return false;
              }
            });
          }
          function checkSeasonCross(seasons) {
            Object.values(seasons).forEach((season) => {
              let start = moment(
                `${season.start_date_day}.${season.start_date_month}`,
                "DD.MM"
              );
              let end = moment(
                `${season.end_date_day}.${season.end_date_month}`,
                "DD.MM"
              );
              self.all_seasons.forEach((all_season) => {
                let start_check = moment(
                  `${all_season.start_date_day}.${all_season.start_date_month}`,
                  "DD.MM"
                );
                let end_check = moment(
                  `${all_season.end_date_day}.${all_season.end_date_month}`,
                  "DD.MM"
                );
                if (
                  start.isBetween(start_check, end_check) ||
                  end.isBetween(start_check, end_check)
                ) {
                  self.$swal({
                    showConfirmButton: true,
                    icon: "warning",
                    title: self.$t("errors.seasons_cross"),
                  });
                  check = false;
                  return false;
                } else {
                  return true;
                }
              });
            });
          }
          console.log("check", check);
          // Проверка существующих сезонов
          checkSeason(seasons);
          // Проверка пересечения с существующими сезонами
          checkSeasonCross(seasons);
          if (check) {
            Object.values(seasons).forEach((season) => {
              if (
                season.start_date_day &&
                season.start_date_month &&
                season.end_date_day &&
                season.end_date_month
              ) {
                self.isLoading = true;
                self.axios
                  .post(
                    `/api/v1/seasons`,
                    {
                      seasons: {
                        start_date: `${season.start_date_day}.${season.start_date_month}`,
                        end_date: `${season.end_date_day}.${season.end_date_month}`,
                      },
                    },
                    {
                      headers: {
                        Authorization: this.$store.getters.getAuthToken,
                      },
                    }
                  )
                  .then((response) => {
                    let split_start_date = response.data.start_date.split(".");
                    let split_end_date = response.data.end_date.split(".");
                    self.all_seasons.push({
                      id: response.data.id,
                      start_date_day: parseInt(split_start_date[0]),
                      start_date_month: split_start_date[1],
                      end_date_day: parseInt(split_end_date[0]),
                      end_date_month: split_end_date[1],
                    });

                    self.$swal({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      icon: "success",
                      title: self.$t("companies.seasons_added"),
                    });
                  })
                  .catch((error) => {
                    self.setError(error, self.$t("errors.seasons_add"));
                    self.$swal({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      icon: "error",
                      title: self.$t("errors.seasons_add"),
                      text: self.error,
                    });
                  })
                  .finally(() => (self.isLoading = false));
              } else {
                self.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "warning",
                  title: self.$t("errors.seasons_add"),
                  text: self.error,
                });
              }
            });
            self.create_seasons = [];
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteSeason(season) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("companies.seasons_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/seasons/${season.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.all_seasons.splice(this.all_seasons.indexOf(season), 1);
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("companies.seasons_delete_success"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.seasons_delete"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeCreateSeason(index) {
      this.create_seasons.splice(index, 1);
    },
    updateToken() {
      if (
        this.user_role != "guest" &&
        this.user_role != "user" &&
        this.user_role != "partner"
      ) {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("companies.update_token_warning"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .patch(`/api/v1/update_api_token`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.company.token = response.data.token;
                this.$swal(
                  this.$t("custom.updated"),
                  this.$t("companies.update_token_success"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.seasons_delete"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    copyToken() {
      navigator.clipboard.writeText(this.company.token);
      // document.execCommand('copy');
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: this.$t("companies.copy_token_success"),
      });
    },
    saveMessageBookingOn() {
      this.axios
        .patch(
          `/api/v1/company`,
          {
            company: {
              send_start_booking: this.company.send_start_booking,
              send_end_booking: this.company.send_end_booking,
              send_review_reminder: this.company.send_review_reminder,
              send_start_booking_whatsapp:
                this.company.send_start_booking_whatsapp,
              send_end_booking_whatsapp: this.company.send_end_booking_whatsapp,
              send_review_reminder_whatsapp:
                this.company.send_review_reminder_whatsapp,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        });
    },
    removeDemoContent() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("demo_content.remove_demo_content_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.showOverlayRemoveDemo = true;
          this.axios
            .post(`/api/v1/remove_demo_content`,
            {
              company_id: self.$store.getters.getCompany.company.id,
            },
            {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              self.$store.commit("setBannerRemoveDemoContent");
              setTimeout(() => {
                self.showOverlayRemoveDemo = false;
                self.$swal(
                  self.$t("custom.deleted"),
                  self.$t("demo_content.remove_demo_content_success"),
                  "success"
                );
              }, 2000);
            })
            .catch((error) => {
              self.showOverlayRemoveDemo = false;
              self.setError(error, self.$t("errors.error"));
              console.log(error);
            });
        }
      });
    },
    saveNotificationMessages() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .post(
            `/api/v1/company/booking_message`,
            {
              booking_message: {
                custom_html: this.custom_html_template,
                start_message: this.send_start_booking_text,
                end_message: this.send_end_booking_text,
                start_message_whatsapp: this.send_start_booking_text_whatsapp,
                end_message_whatsapp: this.send_end_booking_text_whatsapp,
                review_email: this.review_email_text,
                review_whatsapp: this.review_whatsapp_text,
                accept_message: this.accept_message_text,
                decline_message: this.decline_message_text,
                accept_whatsapp: this.accept_whatsapp,
                decline_whatsapp: this.decline_whatsapp,
                lang: this.booking_message_selected_locale ? this.booking_message_selected_locale : null,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveSendReviewMessage() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.company.send_review_reminder &&
          (this.review_email_text == "" || this.review_email_text == null)
        ) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("custom.no_data"),
          });
          return;
        }
        if (
          this.company.send_end_booking &&
          (this.send_end_booking_text == "" ||
            this.send_end_booking_text == null)
        ) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("custom.no_data"),
          });
          return;
        }
        this.isLoading = true;
        this.axios
          .post(
            `/api/v1/company/booking_message`,
            {
              booking_message: {
                start_message: this.send_start_booking_text,
                end_message: this.send_end_booking_text,
                start_message_whatsapp: this.send_start_booking_text_whatsapp,
                end_message_whatsapp: this.send_end_booking_text_whatsapp,
                review_email: this.review_email_text,
                review_whatsapp: this.review_whatsapp_text,
                accept_message: this.accept_message_text,
                decline_message: this.decline_message_text,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getBookingMessages() {
      this.axios
        .get(`/api/v1/company/booking_messages_with_lang/${this.booking_message_selected_locale}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data && response.data.custom_html) {
            this.custom_html_template = response.data.custom_html;
          } else {
            if (response.data && this.booking_message_selected_locale) {
              this.custom_html_template = true;
            } else {
              this.custom_html_template = false;
            }
          }
          if (
            (response.data && response.data.start_message) ||
            (response.data && response.data.start_message_whatsapp)
          ) {
            this.send_start_booking_text = response.data.start_message;
            this.send_start_booking_text_whatsapp =
              response.data.start_message_whatsapp;
          } else {
            this.send_start_booking_text = null;
            this.send_start_booking_text_whatsapp = null;
          }
          if (
            (response.data && response.data.end_message) ||
            (response.data && response.data.end_message_whatsapp)
          ) {
            this.send_end_booking_text = response.data.end_message;
            this.send_end_booking_text_whatsapp =
              response.data.end_message_whatsapp;
          } else {
            this.send_end_booking_text = null;
            this.send_end_booking_text_whatsapp = null;
          }
          if (response.data && response.data.review_email) {
            this.review_email_text = response.data.review_email;
          } else {
            this.review_email_text = null;
          }
          if (response.data && response.data.review_whatsapp) {
            this.review_whatsapp_text = response.data.review_whatsapp;
          } else {
            this.review_whatsapp_text = null;
          }
          if (response.data && response.data.accept_message) {
            this.accept_message_text = response.data.accept_message;
          } else {
            this.accept_message_text = null;
          }
          if (response.data && response.data.decline_message) {
            this.decline_message_text = response.data.decline_message;
          } else {
            this.decline_message_text = null;
          }
          if (response.data && response.data.accept_whatsapp) {
            this.accept_whatsapp = response.data.accept_whatsapp;
          } else {
            this.accept_whatsapp = null;
          }
          if (response.data && response.data.decline_whatsapp) {
            this.decline_whatsapp = response.data.decline_whatsapp;
          } else {
            this.decline_whatsapp = null;
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    periodsFormat(periods) {
      let self = this;
      if (periods) {
        let result = [];
        periods.forEach((period) => {
          result.push(` ${period} ${self.$t("companies.days")}`);
        });
        return result.toString();
      }
    },
    addClientSource() {
      this.clients_sources.push({ value: "" });
    },
    deleteClientSource(index) {
      this.clients_sources.splice(index, 1);
    },
    createWhatsappInstance() {
      if (this.main_company.balance > 0) {
        let self = this;
        this.$swal({
          title: this.$t("companies.whatsapp_create_instance_warning"),
          text: this.$t("companies.whatsapp_create_instance_warning_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                "/api/v1/create_whatsapp_instance",
                {
                  company_id: this.company.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken
                  }
                }
              )
              .then((responce) => {
                console.log(responce.data)
                this.$swal({
                  icon: "success",
                  title: this.$t('companies.whatsapp_create_instance_success'),
                  text: this.$t('companies.whatsapp_create_instance_success_text'),
                  showCancelButton: false,
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.error"));
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t('companies.whatsapp_create_instance_error'),
          text: this.$t('companies.whatsapp_create_instance_no_balance'),
          showCancelButton: false,
        });
      }
    },
    deleteWhatsappInstance() {
      let self = this;
      this.$swal({
        title: this.$t("companies.whatsapp_delete_instance_warning"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/delete_whatsapp_instance",
              {
                company_id: this.company.id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken
                }
              }
            )
            .then((responce) => {
              console.log(responce.data)
              this.$swal({
                icon: "success",
                title: this.$t('companies.whatsapp_delete_instance_success'),
                showCancelButton: false,
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
    authoriseWhatsapp() {
      if ((this.company.chat_api_instance && this.company.chat_api_instance.length > 0 && this.company.chat_api_token && this.company.chat_api_token.length > 0)) {
        let self = this;
        this.isLoading = true;
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/qr/${this.company.chat_api_token}`,
          method: "get",
        })
          .then((response) => {
            if (response.data && response.data.type == 'qrCode') {
              self.qrCode = response.data.message;
              self.qrCodeDialog = true;
            }
            if (response.data && response.data.type == 'error') {
              self.$swal({
                title: self.$t("companies.whatsapp_authorise_error"),
                text: response.data.message,
                icon: "error",
                showCancelButton: false,
              });
            }
            if (response.data && response.data.type == 'alreadyLogged') {
              self.$swal({
                title: self.$t("companies.whatsapp_authorise_success"),
                text: response.data.message,
                icon: "success",
                showCancelButton: false,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Uppy,
    UppyEntity,
    UppyProlong,
    UppyInvoice,
  },
};
</script>
<style>
.dark .rx-content {
  color: #000;
}

.no-redactorx .rx-container {
  display: none;
}
</style>

