<template>
  <v-flex class="d-flex">
    <!-- <v-card>
      <help-drawer></help-drawer>
    </v-card> -->
    <v-container fluid>
      <v-card>
        <v-card-title>
          <h2>{{ $t("doc_variables.variables_title") }}</h2>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <!-- Физлицо -->
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_phiz") }}</h3>
                <p>{date} - {{ $t("doc_variables.date") }}</p>
                <p>{date_short} - {{ $t("doc_variables.date_short") }}</p>
                <p>{serial_number} - {{ $t("doc_variables.serial_number") }}</p>
                <p>{employee_full_name} - {{ $t("doc_variables.employee_full_name") }}</p>
                <p>{employee_short_name} - {{ $t("doc_variables.employee_short_name") }}</p>
                <p>{first_name} - {{ $t("doc_variables.first_name") }}</p>
                <p>{middle_name} - {{ $t("doc_variables.middle_name") }}</p>
                <p>{last_name} - {{ $t("doc_variables.last_name") }}</p>
                <p>{short_name} - {{ $t("doc_variables.fio") }}</p>
                <p>{booking_id} - {{ $t("doc_variables.booking_id") }}</p>
                <p>{client_id} - {{ $t("doc_variables.client_id") }}</p>
                <p>{car_id} - {{ $t("doc_variables.car_id") }}</p>
                <p>{date_birth} - {{ $t("doc_variables.date_birth") }}</p>
                <p>
                  {passport_series} - {{ $t("doc_variables.passport_series") }}
                </p>
                <p>
                  {passport_number} - {{ $t("doc_variables.passport_number") }}
                </p>
                <p>
                  {passport_issued} - {{ $t("doc_variables.passport_issued") }}
                </p>
                <p>{driver_series} - {{ $t("doc_variables.driver_series") }}</p>
                <p>{driver_number} - {{ $t("doc_variables.driver_number") }}</p>
                <p>{driver_issued} - {{ $t("doc_variables.driver_issued") }}</p>
                <p>{taxi_license} - {{ $t("doc_variables.taxi_license") }}</p>
                <p>{country} - {{ $t("doc_variables.country") }}</p>
                <p>{city} - {{ $t("doc_variables.city") }}</p>
                <p>{address} - {{ $t("doc_variables.address") }}</p>
                <p>{phone} - {{ $t("doc_variables.phone") }}</p>
                <p>{email} - {{ $t("doc_variables.email") }}</p>
                <p>{start_date} - {{ $t("doc_variables.start_date") }}</p>
                <p>{end_date} - {{ $t("doc_variables.end_date") }}</p>
                <p>
                  {start_date_short} -
                  {{ $t("doc_variables.start_date_short") }}
                </p>
                <p>
                  {end_date_short} - {{ $t("doc_variables.end_date_short") }}
                </p>
                <p>
                  {location_start} - {{ $t("doc_variables.location_start") }}
                </p>
                <p>{location_end} - {{ $t("doc_variables.location_end") }}</p>

                <p>
                  {delivery_start} - {{ $t("doc_variables.delivery_start") }}
                </p>
                <p>{delivery_end} - {{ $t("doc_variables.delivery_end") }}</p>
                <p>{equipment} - {{ $t("doc_variables.equipment") }}</p>
                <p>
                  {additional_hours} -
                  {{ $t("doc_variables.additional_hours") }}
                </p>
                <p>{hours_cost} - {{ $t("doc_variables.hours_cost") }}</p>
                <p>{price_hour} - {{ $t("doc_variables.price_hour") }}</p>
                <p>
                  {add_drivers_cost} -
                  {{ $t("doc_variables.add_drivers_cost") }}
                </p>
                <p>{insurance} - {{ $t("doc_variables.insurance") }}</p>
                <p>{clean} - {{ $t("doc_variables.clean") }}</p>
                <p>{other} - {{ $t("doc_variables.other") }}</p>
                <p>
                  {has_delivery_start} -
                  {{ $t("doc_variables.has_delivery_start") }}
                </p>
                <p>
                  {has_delivery_end} -
                  {{ $t("doc_variables.has_delivery_end") }}
                </p>
                <p>{has_equipment} - {{ $t("doc_variables.has_equipment") }}</p>
                <p>
                  {has_additional_hours} -
                  {{ $t("doc_variables.has_additional_hours") }}
                </p>
                <p>
                  {has_hours_cost} - {{ $t("doc_variables.has_hours_cost") }}
                </p>
                <p>
                  {has_add_drivers_cost} -
                  {{ $t("doc_variables.has_add_drivers_cost") }}
                </p>
                <p>{has_clean} - {{ $t("doc_variables.has_clean") }}</p>
                <p>{has_insurance} - {{ $t("doc_variables.has_insurance") }}</p>
                <p>{has_other} - {{ $t("doc_variables.has_other") }}</p>
                <p>{has_sale} - {{ $t("doc_variables.has_sale") }}</p>
                <p>{has_sale_cash} - {{ $t("doc_variables.has_sale_cash") }}</p>
                <p>{sale} - {{ $t("doc_variables.sale") }}</p>
                <p>{sale_cash} - {{ $t("doc_variables.sale_cash") }}</p>
                <p>{total} - {{ $t("doc_variables.total") }}</p>
                <p>{prepaid} - {{ $t("doc_variables.prepaid") }}</p>

                <p>{start_mileage} - {{ $t("doc_variables.start_mileage") }}</p>
                <p>{end_mileage} - {{ $t("doc_variables.end_mileage") }}</p>
                <p>{day_cost} - {{ $t("doc_variables.day_cost") }}</p>
                <p>{price} - {{ $t("doc_variables.price") }}</p>
                <p>{cost} - {{ $t("doc_variables.cost") }}</p>
                <p>{days} - {{ $t("doc_variables.days") }}</p>
                <p>{car_name} - {{ $t("doc_variables.car_name") }}</p>
                <p>{car_code} - {{ $t("doc_variables.car_code") }}</p>
                <p>{car_number} - {{ $t("doc_variables.car_number") }}</p>
                <p>{car_vin} - {{ $t("doc_variables.car_vin") }}</p>
                <p>{car_body} - {{ $t("doc_variables.car_body") }}</p>
                <p>{car_pts} - {{ $t("doc_variables.car_pts") }}</p>
                <p>{car_reg} - {{ $t("doc_variables.car_reg") }}</p>
                <p>{car_color} - {{ $t("doc_variables.car_color") }}</p>
                <p>{car_year} - {{ $t("doc_variables.car_year") }}</p>
                <p>{car_fuel} - {{ $t("doc_variables.car_fuel") }}</p>
                <p>{car_purchase_price} - {{ $t("doc_variables.car_purchase_price") }}</p>
                <p>
                  {additional_services} -
                  {{ $t("doc_variables.additional_services") }}
                </p>
                <p>
                  {has_additional_services} -
                  {{ $t("doc_variables.has_additional_services") }}
                </p>
                <p>{deposit} - {{ $t("doc_variables.deposit") }}</p>
                <p>
                  {add_drivers_info} -
                  {{ $t("doc_variables.add_drivers_info") }}
                </p>
                <p>
                  {add_drivers_info_full} -
                  {{ $t("doc_variables.add_drivers_info_full") }}
                </p>
                <p>{franchise} - {{ $t("doc_variables.franchise") }}</p>
                <p>{max_fine} - {{ $t("doc_variables.max_fine") }}</p>
                <p>{repair_cost} - {{ $t("doc_variables.repair_cost") }}</p>
                <p>
                  {custom_field_1} - {{ $t("doc_variables.custom_field_1") }}
                </p>
                <p>
                  {custom_field_2} - {{ $t("doc_variables.custom_field_2") }}
                </p>
                <p>
                  {custom_field_3} - {{ $t("doc_variables.custom_field_3") }}
                </p>
                <p>
                  {cars_custom_field_1} -
                  {{ $t("doc_variables.cars_custom_field_1") }}
                </p>
                <p>
                  {cars_custom_field_2} -
                  {{ $t("doc_variables.cars_custom_field_2") }}
                </p>
                <p>
                  {cars_custom_field_3} -
                  {{ $t("doc_variables.cars_custom_field_3") }}
                </p>
                <p>
                  {total_without_commission} -
                  {{ $t("doc_variables.total_without_commission") }}
                </p>
                <p>
                  {total_with_deposit_without_commission} -
                  {{ $t("doc_variables.total_with_deposit_without_commission") }}
                </p>
                <p>
                  {day_cost_without_commission} -
                  {{ $t("doc_variables.day_cost_without_commission") }}
                </p>
                <p>
                  {price_without_commission} -
                  {{ $t("doc_variables.price_without_commission") }}
                </p>
                <p>
                  {cost_without_commission} -
                  {{ $t("doc_variables.cost_without_commission") }}
                </p>
              </v-flex>
              <!-- Юрлицо -->
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_ur") }}</h3>
                <p>{date} - {{ $t("doc_variables.date") }}</p>
                <p>{date_short} - {{ $t("doc_variables.date_short") }}</p>
                <p>{serial_number} - {{ $t("doc_variables.serial_number") }}</p>
                <p>{employee_full_name} - {{ $t("doc_variables.employee_full_name") }}</p>
                <p>{employee_short_name} - {{ $t("doc_variables.employee_short_name") }}</p>
                <p>{booking_id} - {{ $t("doc_variables.booking_id") }}</p>
                <p>{client_id} - {{ $t("doc_variables.client_id") }}</p>
                <p>{car_id} - {{ $t("doc_variables.car_id") }}</p>
                <p>{start_date} - {{ $t("doc_variables.start_date") }}</p>
                <p>{end_date} - {{ $t("doc_variables.end_date") }}</p>
                <p>
                  {start_date_short} -
                  {{ $t("doc_variables.start_date_short") }}
                </p>
                <p>
                  {end_date_short} - {{ $t("doc_variables.end_date_short") }}
                </p>
                <p>
                  {location_start} - {{ $t("doc_variables.location_start") }}
                </p>
                <p>{location_end} - {{ $t("doc_variables.location_end") }}</p>

                <p>
                  {delivery_start} - {{ $t("doc_variables.delivery_start") }}
                </p>
                <p>{delivery_end} - {{ $t("doc_variables.delivery_end") }}</p>
                <p>{equipment} - {{ $t("doc_variables.equipment") }}</p>
                <p>
                  {additional_hours} -
                  {{ $t("doc_variables.additional_hours") }}
                </p>
                <p>{hours_cost} - {{ $t("doc_variables.hours_cost") }}</p>
                <p>{price_hour} - {{ $t("doc_variables.price_hour") }}</p>
                <p>
                  {add_drivers_cost} -
                  {{ $t("doc_variables.add_drivers_cost") }}
                </p>
                <p>{insurance} - {{ $t("doc_variables.insurance") }}</p>
                <p>{clean} - {{ $t("doc_variables.clean") }}</p>
                <p>{other} - {{ $t("doc_variables.other") }}</p>
                <p>
                  {has_delivery_start} -
                  {{ $t("doc_variables.has_delivery_start") }}
                </p>
                <p>
                  {has_delivery_end} -
                  {{ $t("doc_variables.has_delivery_end") }}
                </p>
                <p>{has_equipment} - {{ $t("doc_variables.has_equipment") }}</p>
                <p>
                  {has_additional_hours} -
                  {{ $t("doc_variables.has_additional_hours") }}
                </p>
                <p>
                  {has_hours_cost} - {{ $t("doc_variables.has_hours_cost") }}
                </p>
                <p>
                  {has_add_drivers_cost} -
                  {{ $t("doc_variables.has_add_drivers_cost") }}
                </p>
                <p>{has_clean} - {{ $t("doc_variables.has_clean") }}</p>
                <p>{has_insurance} - {{ $t("doc_variables.has_insurance") }}</p>
                <p>{has_other} - {{ $t("doc_variables.has_other") }}</p>
                <p>{has_sale} - {{ $t("doc_variables.has_sale") }}</p>
                <p>{has_sale_cash} - {{ $t("doc_variables.has_sale_cash") }}</p>
                <p>{sale} - {{ $t("doc_variables.sale") }}</p>
                <p>{sale_cash} - {{ $t("doc_variables.sale_cash") }}</p>
                <p>{total} - {{ $t("doc_variables.total") }}</p>
                <p>
                  {total_with_deposit} -
                  {{ $t("doc_variables.total_with_deposit") }}
                </p>
                <p>{prepaid} - {{ $t("doc_variables.prepaid") }}</p>

                <p>{start_mileage} - {{ $t("doc_variables.start_mileage") }}</p>
                <p>{end_mileage} - {{ $t("doc_variables.end_mileage") }}</p>
                <p>{day_cost} - {{ $t("doc_variables.day_cost") }}</p>
                <p>{price} - {{ $t("doc_variables.price") }}</p>
                <p>{cost} - {{ $t("doc_variables.cost") }}</p>
                <p>{days} - {{ $t("doc_variables.days") }}</p>
                <p>{car_name} - {{ $t("doc_variables.car_name") }}</p>
                <p>{car_code} - {{ $t("doc_variables.car_code") }}</p>
                <p>{car_number} - {{ $t("doc_variables.car_number") }}</p>
                <p>{car_vin} - {{ $t("doc_variables.car_vin") }}</p>
                <p>{car_body} - {{ $t("doc_variables.car_body") }}</p>
                <p>{car_pts} - {{ $t("doc_variables.car_pts") }}</p>
                <p>{car_reg} - {{ $t("doc_variables.car_reg") }}</p>
                <p>{car_color} - {{ $t("doc_variables.car_color") }}</p>
                <p>{car_year} - {{ $t("doc_variables.car_year") }}</p>
                <p>{car_fuel} - {{ $t("doc_variables.car_fuel") }}</p>
                <p>{car_purchase_price} - {{ $t("doc_variables.car_purchase_price") }}</p>
                <p>
                  {additional_services} -
                  {{ $t("doc_variables.additional_services") }}
                </p>
                <p>
                  {has_additional_services} -
                  {{ $t("doc_variables.has_additional_services") }}
                </p>
                <p>{deposit} - {{ $t("doc_variables.deposit") }}</p>
                <p>{entity_name} - {{ $t("doc_variables.entity_name") }}</p>
                <p>{ceo} - {{ $t("doc_variables.ceo") }}</p>
                <p>{reg_form} - {{ $t("doc_variables.reg_form") }}</p>
                <p>{entity_phone} - {{ $t("doc_variables.entity_phone") }}</p>
                <p>{country} - {{ $t("doc_variables.country") }}</p>
                <p>{city} - {{ $t("doc_variables.city") }}</p>
                <p>{entity_adress} - {{ $t("doc_variables.entity_adress") }}</p>
                <p>{inn} - {{ $t("doc_variables.inn") }}</p>
                <p>{ogrn} - {{ $t("doc_variables.ogrn") }}</p>
                <p>{acc_number} - {{ $t("doc_variables.acc_number") }}</p>
                <p>{bank} - {{ $t("doc_variables.bank") }}</p>
                <p>{korr} - {{ $t("doc_variables.korr") }}</p>
                <p>{bik} - {{ $t("doc_variables.bik") }}</p>
                <p>
                  {add_drivers_info} -
                  {{ $t("doc_variables.add_drivers_info") }}
                </p>
                <p>
                  {add_drivers_info_full} -
                  {{ $t("doc_variables.add_drivers_info_full") }}
                </p>
                <p>{franchise} - {{ $t("doc_variables.franchise") }}</p>
                <p>{max_fine} - {{ $t("doc_variables.max_fine") }}</p>
                <p>{repair_cost} - {{ $t("doc_variables.repair_cost") }}</p>
                <p>
                  {custom_field_1} - {{ $t("doc_variables.custom_field_1") }}
                </p>
                <p>
                  {custom_field_2} - {{ $t("doc_variables.custom_field_2") }}
                </p>
                <p>
                  {custom_field_3} - {{ $t("doc_variables.custom_field_3") }}
                </p>
                <p>
                  {cars_custom_field_1} -
                  {{ $t("doc_variables.cars_custom_field_1") }}
                </p>
                <p>
                  {cars_custom_field_2} -
                  {{ $t("doc_variables.cars_custom_field_2") }}
                </p>
                <p>
                  {cars_custom_field_3} -
                  {{ $t("doc_variables.cars_custom_field_3") }}
                </p>
                <p>
                  {total_without_commission} -
                  {{ $t("doc_variables.total_without_commission") }}
                </p>
                <p>
                  {total_with_deposit_without_commission} -
                  {{ $t("doc_variables.total_with_deposit_without_commission") }}
                </p>
                <p>
                  {day_cost_without_commission} -
                  {{ $t("doc_variables.day_cost_without_commission") }}
                </p>
                <p>
                  {price_without_commission} -
                  {{ $t("doc_variables.price_without_commission") }}
                </p>
                <p>
                  {cost_without_commission} -
                  {{ $t("doc_variables.cost_without_commission") }}
                </p>
              </v-flex>
              <!-- Счет и акт -->
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_count") }}</h3>
                <p>{date} - {{ $t("doc_variables.date") }}</p>
                <p>{date_short} - {{ $t("doc_variables.date_short") }}</p>
                <p>{serial_number} - {{ $t("doc_variables.serial_number") }}</p>
                <p>{start_date} - {{ $t("doc_variables.start_date") }}</p>
                <p>{end_date} - {{ $t("doc_variables.end_date") }}</p>
                <p>
                  {start_date_short} -
                  {{ $t("doc_variables.start_date_short") }}
                </p>
                <p>
                  {end_date_short} - {{ $t("doc_variables.end_date_short") }}
                </p>
                <p>{day_cost} - {{ $t("doc_variables.day_cost") }}</p>
                <p>{cost} - {{ $t("doc_variables.cost") }}</p>
                <p>{days} - {{ $t("doc_variables.days") }}</p>
                <p>{car_name} - {{ $t("doc_variables.car_name") }}</p>
                <p>{number} - {{ $t("doc_variables.number") }}</p>
                <p>{rent} - {{ $t("doc_variables.rent") }}</p>
                <p>{deposit} - {{ $t("doc_variables.deposit") }}</p>
                <p>{delivery} - {{ $t("doc_variables.delivery") }}</p>
                <p>{clean} - {{ $t("doc_variables.clean") }}</p>
                <p>{gas} - {{ $t("doc_variables.gas") }}</p>
                <p>{damage} - {{ $t("doc_variables.damage") }}</p>
                <p>{fines} - {{ $t("doc_variables.fines") }}</p>
                <p>{mileage} - {{ $t("doc_variables.mileage") }}</p>
                <p>{hours_cost} - {{ $t("doc_variables.hours_cost") }}</p>
                <p>{insurance} - {{ $t("doc_variables.insurance") }}</p>
                <p>{equipment} - {{ $t("doc_variables.equipment") }}</p>
                <p>{other} - {{ $t("doc_variables.other") }}</p>
                <p>{sum} - {{ $t("doc_variables.sum") }}</p>
                <p>{sum_words} - {{ $t("doc_variables.sum_words") }}</p>
                <p>{entity_name} - {{ $t("doc_variables.entity_name") }}</p>
                <p>{ceo} - {{ $t("doc_variables.ceo") }}</p>
                <p>{reg_form} - {{ $t("doc_variables.reg_form") }}</p>
                <p>{entity_phone} - {{ $t("doc_variables.entity_phone") }}</p>
                <p>{entity_adress} - {{ $t("doc_variables.entity_adress") }}</p>
                <p>{country} - {{ $t("doc_variables.country") }}</p>
                <p>{city} - {{ $t("doc_variables.city") }}</p>
                <p>{inn} - {{ $t("doc_variables.inn") }}</p>
                <p>{ogrn} - {{ $t("doc_variables.ogrn") }}</p>
                <p>{acc_number} - {{ $t("doc_variables.acc_number") }}</p>
                <p>{bank} - {{ $t("doc_variables.bank") }}</p>
                <p>{korr} - {{ $t("doc_variables.korr") }}</p>
                <p>{bik} - {{ $t("doc_variables.bik") }}</p>
                <p>{doc_number} - {{ $t("doc_variables.doc_number") }}</p>
                <v-divider></v-divider>
                <p>{#services} - {{ $t("doc_variables.services") }}</p>
              </v-flex>
              <v-flex md12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_prolong") }}</h3>
                <p>{{ $t("doc_variables.all_from_customer") }}</p>
                <p>{prolong_days} - {{ $t("doc_variables.prolong_days") }}</p>
                <p>{prolong_price} - {{ $t("doc_variables.prolong_price") }}</p>
                <p>{prolong_sum} - {{ $t("doc_variables.prolong_sum") }}</p>
                <p>
                  {prolong_end_date} -
                  {{ $t("doc_variables.prolong_end_date") }}
                </p>
              </v-flex>
              <v-flex md12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_count") }}</h3>
                <p>{#has_delivary_start}</p>
                <p>
                  {{ $t("doc_variables.ifelse_delivery") }} {delivary_start}
                </p>
                <p>{/has_delivary_start}</p>
              </v-flex>
              <v-flex md4> </v-flex>
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_cycles_counts") }}</h3>
                <p>{#services}</p>
                <p>{number}. {service}, {sum}</p>
                <p>{/services}</p>
              </v-flex>
              <v-flex md4>
                <h3>{{ $t("doc_variables.variables_add_drivers") }}</h3>
                <div><p>{#add_drivers_array}</p></div>
                <div class="pl-2"><p>{#has_add_drivers}</p></div>
                <div class="pl-4"><p>{#drivers}</p></div>
                <div class="pl-6"><p>{driver_name}</p></div>
                <div class="pl-6"><p>{driver_lastname}</p></div>
                <div class="pl-6"><p>{driver_middlename}</p></div>
                <div class="pl-6"><p>{driver_birthday}</p></div>
                <div class="pl-6"><p>{driver_passport_series}</p></div>
                <div class="pl-6"><p>{driver_passport_number}</p></div>
                <div class="pl-6"><p>{driver_passport_issued}</p></div>
                <div class="pl-6"><p>{driver_country}</p></div>
                <div class="pl-6"><p>{driver_city}</p></div>
                <div class="pl-6"><p>{driver_address}</p></div>
                <div class="pl-6"><p>{driver_driver_series}</p></div>
                <div class="pl-6"><p>{driver_driver_number}</p></div>
                <div class="pl-6"><p>{driver_driver_issued}</p></div>
                <div class="pl-4"><p>{/drivers}</p></div>
                <div class="pl-2"><p>{/has_add_drivers}</p></div>
                <div><p>{/add_drivers_array}</p></div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>
<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data: () => ({
    isLoading: false,
    error: "",
  }),
  created() {},
  methods: {
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router.push({
          path: fpath,
        });
      }
    },
  },
  components: {
    HelpDrawer,
  },
};
</script>
